.idHeader {
  margin: .75rem 0 .25rem;
}

.imageGrid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 0.5rem;
  margin: 0 -.5rem;
}
    
.imageItem  {
  flex: 1;
  position: relative;
}

.imageItem img {
  width: 100%;  
  background-color: #ccc;
  border-radius: 1.5rem;
  cursor: pointer;
  opacity: .7;
}

.imageItem img.placeholder {
  background-color: #ebebeb; /* similar to MUI inputs */  
  opacity: 1;
}

.disclaimer {
  flex: 1;
  font-size: .875rem;
  padding: 0 1rem;
  margin-bottom: 1.5rem;
}
/* reg flow */
.uploadButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* account page */
@media screen and (min-width:600px) {
  .updateIdButton {
    margin-top: 1rem;
  }
}

.progressWrap {
  position: absolute;
  top: .25rem;
  right: .25rem;
}

.updateMsg {
  margin: 1rem .5rem 2rem;
  text-align: center;
}

.statusText {
  font: var(--zrFontSemi);
}

.sizeIcon {
  color: var(--zrPrimaryDark);
  background-color: #fff; 
  font-size: 3rem !important; 
  vertical-align: -6px;
  border-radius: 50%;
}

.sizeMsg {
  display: block;
  text-align: center;
  font-size: .75rem;
  color: var(--zrPrimaryDark);
  font-weight: 600;
}

.warningIcon,
.warningMsg {
  color: var(--zrTextRed);
}

@media (max-width:480px) {
  .imageGrid { display: block; }
  .imageItem { margin: 0 .5rem .5rem; }
}
