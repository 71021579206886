.outerWrap {
  padding: 1.5rem 0 0 0;
}

.gradientWrap {
  position: relative;
  min-height: 122px;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  border-radius: .5rem;
  box-sizing: border-box;
  padding: 1rem 1.25rem;
  background: #6b4674;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #6b4674, #f72c5a);
  background: linear-gradient(to left, #6b4674, #f72c5a);
}

.backLink {
  cursor: pointer;
  position: absolute;
  top: 16px;
  left: 12px;
  z-index: 1;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  line-height: 126%;
  padding-top: 62px;
  box-sizing: border-box;
  height: 90px;
  width: 90px;
  text-align: center;
  text-shadow: 0 0 2px #000;
}

.backIcon {
  position: absolute;
  height: 58px;
  top: 4px;
  left: 17px;
}

.grid {
  display: flex;
  align-items: center;
  margin: 0 auto;
}

.title {
  font-size: 48px;
  font-weight: 600;
  line-height: 130%;
  color: #fff;
  text-shadow: 0 0 5px #000;
  white-space: nowrap;
}

@media screen and (max-width:1024px) {
  .title {
    font-size: 40px;
  }
}

@media screen and (max-width:800px) {
  .outerWrap {
    padding: 0;
    margin: 0 -2rem;
  }
  .gradientWrap {
    border-radius: 0;
    min-height: 100px;
  }
  .backLink {
    top: 20px;
    font-size: 13px;
    height: 48px;
    width: 48px;
    padding-top: 39px;
  }
  .backIcon {
    height: 36px;
    top: 4px;
    left: 6px;
  }
}

@media screen and (max-width:480px) {
  .outerWrap {
    margin: 0 -1rem;
  }
  .title {
    font-size: 28px;
  }
}
