.alert {
  margin: 1rem 0;
  padding-top: 6px;
}

.alertHdr {
  font-size: 1.125rem;
  font-weight: 600;
  margin: -2px 0 4px; 
}

.detail {
  margin-bottom: 6px;    
}

.detail:last-of-type {
  margin-bottom: 0;    
}

.chatLink {
  cursor: pointer;
  font-weight: 600;
  text-decoration: underline;
}
