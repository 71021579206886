/* MuiModal DEFAULT styles */
.muiModal {
  width: 600px;
  max-width: calc(100vw - 32px);
  position: relative;
  box-sizing: border-box;
  margin: 4rem auto;
  border-radius: 8px !important;
  outline: none !important;
  overflow: hidden;
}

@media screen and (max-width:600px) {
  .muiModal {
    margin: 4rem auto;
  }
}

/* CloseModalIcon */
.closeWrapper {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 48px;
  top: 0;
  right: 0;
}

.closeIcon {
  cursor: pointer;
  position: absolute;
  z-index: 2;
  right: 10px;
  top: 10px;
  height: 1rem;
  background-color: #fff;
  padding: .25rem;
  border-radius: 50%;
  box-shadow: 0 0 3px #13460188;
}

.closeLabel {
  cursor: pointer;
  background-color: #fff;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  margin-top: -4px;
  text-align: center;
  font-weight: 600;
  line-height: 200%;
  text-decoration: underline;
}

/* onfleet map overrides */
.onfleetMapCallout {
  cursor: pointer;
  position: relative;
  margin: 1rem 0 2rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
}

.onfleetCalloutText {
  position: absolute;
  z-index: 1;
  top: 42px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #c5dff8b8;
  width: 500px;
  max-width: 90%;
  border-radius: 8px;
  padding: 18px 0;
  text-align: center;
  text-decoration: underline;
  color: #000;
  font-weight: 600;
  font-size: 1.125rem;
}

.onfleetCalloutWord {
  display: inline;
}

.imgWrap {
  height: 150px;
  width: 100%;
  position: relative;
}

.mapThumb {
  position: absolute;
  right: -54px;
}

.pinIcon {
  vertical-align: -8px;
  font-size: 28px;
  margin-right: 1px;
}

.onfleetModal {
  width: 420px; /* onfleet map content width */
  max-width: calc(100vw - 32px);
  background-color: #fff;
}

/* Height is optimized for the onfleet map */
.onfleetIframe {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;
  width: 100%;
  height: 66vh;
  border: none;
}
