.surveyWrapper {
  padding: 24px 0px 12px;
  background-color: #000;
}

/* The iframe for the static ZR page */
.surveyIframe {
  border: none;
  height: 64vh;
  width: 100%;
}

/* The survey loading spinner container */
.spinnerWrap {
  position: absolute;
  height: 50px;
  top: 80px;
  left: 0;
  right: 0;
  text-align: center;
}

.completedMsg {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 72px;
  background-color: #000;
  color: #fff;
  display: flex;
  justify-content: space-around;
}

.completedCloseLink {
  cursor: pointer;
  font-size: 1.25rem;
  font-weight: 600;
  padding: 18px 24px;
  text-decoration: underline;
}

.linkStyle {
  cursor: pointer;
  text-decoration: underline;
  margin: 0 0 0 .5em;
}
