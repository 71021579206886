/* TODO: Swap in MUI Alert */

.successMessage,
.errorMessage {
  display: inline-block;
  position: relative;
  margin: 1rem 0 0;
  padding: .5rem .5rem .5rem 2rem;
  line-height: 133%;
  border-radius: .25rem;
}

.successMessage {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  color: var(--zrPrimaryDark);
  background-color: #fff;
  font: var(--zrFontSemi);
}
.successIcon {
  flex: 0;
}
.successText {
  flex: 1;
  margin-left: .25rem;
}


.errorMessage {
  font: var(--zrFontSemi);
  font-size: .875rem;
  color: #000;
  background-color: #fbe6e6; 
}

.simpleErrorMessage {
  align-content: center;
  font: var(--zrFontSemi);
  font-size: 1.125rem;
  color: var(--zrTextRed);
  margin: 1rem 0;
}  

.simpleErrorMessage .icon {
  flex: 0 0 24px;
  margin-right: .25rem;
}

.simpleErrorMessage .text {
  flex: 1;
  text-align: left;
  padding-top: 2px;
}  

.successMessage img {
  position: absolute;
  left: 8px;
  top: 10px;
}

.errorMessage svg {
  position: absolute;
  left: 8px;
  top: .5rem;
}

/* StatusPill */
.statusPill {
  font: var(--zrFontSemi);
  font-size: .875rem;
  position: absolute;
  z-index: 1;
  padding: 2px 9px;
  border-radius: .75rem;
  background-color: var(--zrOrange);
  color: #000;
}

/* ActiveFilterPills */
:global .MuiSvgIcon-root.MuiChip-deleteIcon {
  color: var(--zrPrimaryVeryDark);
}
:global .MuiSvgIcon-root.MuiChip-deleteIcon:hover {
  color: #000;
}

.filterPills {
  margin: 1rem 0; 
  text-align: center;
}

.filterPillsNav {
  margin: 1rem 0; 
  text-align: left;
}

.filterPill {
  text-transform: capitalize;
  color: var(--zrPrimarydark) !important;
  background-color: var(--zrLightBackground) !important;
  margin: 8px;
}

/* When filter panel is suppressed */
@media screen and (max-width:1024px) {
  .filterPills {
    margin: -2px -8px 6px;
  }
}
/* When filter panel is displayed */
@media screen and (min-width:1025px) {
  .filterPillsNav {
    display: none;
  }   
}
