/**
 * NOTE: There are selectors here that span 2 components:
 * ( .paymentMethods .paymentValue )
 */

/* Select Payment */
.radioGrid {
  display: flex;
  align-items: center;
  margin-bottom: .25rem;
}
.radioLabelWrap {
  position: relative;
}
.radioLabel {
  font-weight: 900;
}
.radioDesc {
  margin: 0 6px 0 0;
}
.radioDescTag {
  color: var(--zrPrimaryVeryDark);
  background-color: #d7e8f688;
  font-weight: 900;
  font-size: .675rem;
  position: absolute;
  z-index: -1;
  top: -13px;
  right: -16px;
  padding: 3px 6px;
  border-radius: 6px;
  transform: rotate(6deg);
}

.apSetupNote {
  color: var(--zrPrimaryDark);
  font-size: .875rem;
  font-weight: 400;
  margin: -6px 0 6px 29px;
}

.apLink {
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600;
}

/* This is just the available methods info section */
.paymentMethods {
  margin-top: .5rem;
  font-size: .875rem;
}

.paymentMethods .paymentLabel,
.paymentMethods .paymentValue {
  white-space: nowrap;
}

.paymentMethods .paymentValue {
  margin-left: .75rem;
}

/* PaymentMethods component */
.paymentValue .inlineValue {
  margin-right: 0;
}
.paymentValue .inlineValue:after {
  content: ', ';
}
.paymentValue:last-of-type .inlineValue:after {
  content: '';
}

@media screen and (max-width:600px) {
  .paymentMethods .paymentLabel,
  .paymentMethods .paymentValues {
    display: block;
  }
  .paymentMethods .paymentValue {
    margin-left: 0;
    margin-right: .75rem;
  }
  .paymentValues {
    margin-top: .5rem;
  }
}
