.sectionHeader {
  display: flex;
  align-items: center;
  margin: 2rem 0 1rem;
  flex-basis: 100%;
}

.headerAndTagline {
  flex: 1;
}

.sectionTagline {
  font-size: .9rem;
}

.subTitle {
  font-weight: 400;
  font-size: .75em;
}

/* Flower by THC% header sort toggle */
.headerGrid {
  display: inline-flex;
  align-items: center;
}
.headerLink {
  cursor: pointer;
  margin: 0 12px;
  font-size: .675em;
  font-weight: 400;
  text-decoration: underline;
}

/* Delivery by 3pm etc. */
.slotCallouts {
  display:flex;
  align-items: center;
}

.slotDiscountCallout {
  margin-left: 16px;
  font-weight: 600;
  font-size: 14px;
  font-style: italic;
  white-space: nowrap;
  color: rgba(216, 3, 3, 0.87);
}

.categoryName {
  display: inline-block;
  margin-right: .75rem;
  font: var(--zrFontBold);
  font-size: inherit;
}

.suggestedHeader {
  display: inline-block;
  font: var(--zrFontBold);
  font-size: 1.25rem;
}

.seeAllLink {
  cursor: pointer;
  flex: 0;
  justify-self: end;
  padding-left: 2rem;
  margin-bottom: -6px;
  font: var(--zrFontSemi);
  white-space: nowrap;
}

.seeAllLink:hover {
  text-decoration: underline;
}

.seeAllButtonWrap {
  display: none;
  margin: 1rem 0 0;
  padding-bottom: 2rem;
  border-bottom: 1px solid #ccc;
}

.seeAllButtonWrap:last-of-type {
  border-bottom: none;
}

/* MUI accordion overrides */
:global .MuiAccordion-rounded:last-child {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

:global .MuiAccordion-rounded:first-child {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

@media screen and (max-width:600px) {
  .sectionHeader {
    margin: 1.25rem 0 1rem;
  }
  .sectionTagline {
    margin: 3px 0 0; /* slot ETA above */
  }
  /* cat name and count */
  .seeAllLink .viewAllDetails {
    display: none;
  }
}

@media screen and (max-width:480px) {
  .slotCallouts {
    justify-content: space-between;
  }
  .slotDiscountCallout {
    margin-left: 6px;
  }
  .seeAllButtonWrap {
    display: block;
  }
  .seeAllLink {
    display: none;
  }
}
