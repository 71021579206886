.carouselWrap {
  position: relative;
}

.carouselWrap.sponsored  {
  padding: 1rem 1rem .75rem;
  background-color: var(--zrPromoted);
  border-radius: 1rem;
}

.carousel {
  display: flex;
  position: relative; /* for stacking (promos) */
  composes: horizontalScroll from '../common/mixins.css';
}

.carouselItem {
  max-width: 200px; /* was 220 */
  flex: 0 0 100%;
  margin-right: 1rem;    /* part of the carousel item width calc! */
  margin-bottom: .25rem; /* allow for box shadow */
  display: flex;         /* added to support bottom-aligned sale label */
  flex-direction: column;
  justify-content: space-between;
}

/* When full carousel item is clickable (via prop) */
.carouselItem[data-is-clickable="true"] {
  cursor: pointer;
}

.cartSuggestedProducts .carouselItem,
.suggestedProducts .carouselItem {
  max-width: 168px;
}

.promos .carouselItem {
  width: 400px;
  max-width: 75vw;
  flex: 0 0 400px;
}

/* card height is set by flex carouselItem */
.suggestedProducts .carouselItem,
.productCards .carouselItem {
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: .5rem;
}

.cartSuggestedProducts .carouselItem,
.productCards .carouselItem {
  border: 1px solid #e7e7e7;
  border-radius: .5rem;
}

.carouselItem:last-of-type {
  margin-right: 0;
}

/* control div is also used to measure carousel item width */
.controls {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 4px; /* bottom 4px is card shadow */
}

.maskLeft {
  display: none;
  composes: maskLeft from '../common/mixins.css';
  left: -.5rem;
}

.maskRight {
  display: none;
  composes: maskRight from '../common/mixins.css';
  right: -.5rem;
}

.carouselWrap.sponsored .maskLeft,
.carouselWrap.sponsored .maskRight {
  display: none;
}

.controls svg {
  font-size: 0;
  transition: font-size .25s ease-in-out;
}

.scrollArrow {
  cursor: pointer;
  position: absolute;
  display: none;
  opacity: 0;
  z-index: 2;
  top: 50%;
  transform: translateY(-55%);
  width: 0;
  height: 0;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px 0px;
  background-color: #fff;
  border-radius: 50%;
  transition: all .25s ease-in-out;
}

/* Desktop only! hover is wonky in mobile */
@media screen and (min-width:600px) {
  .productCards .carouselItem:hover {
    border: 1px solid #d6d6d6;
    box-shadow: 0 3px 5px #4449; /* #b2b2b2; */
  }
  .carouselWrap:hover .controls svg {
    font-size: 48px;
  }
  .carouselWrap:hover .controls .scrollArrow {
    width: 48px;
    height: 48px;
  }
}

.scrollLft {
  left: -15px;
}

.scrollRgt {
  right: -15px;
}

.withLeftArrow .scrollLft,
.withRightArrow .scrollRgt {
  display: block;
  opacity: 1;
}

.withLeftArrow .maskLeft,
.withRightArrow .maskRight {
  display: block;
}

/* CAROUSEL VARIANTS VIA CarouselType */
.categories .carouselItem {
  max-width: 12.5%;
  min-width: 124px;
  margin-right: 0;
}

@media screen and (max-width:1024px) {
  .carouselWrap:hover .controls .scrollArrow {
    width: 36px;
    height: 36px;
  }
  .carouselWrap:hover .controls svg {
    font-size: 36px;
  }
  /* VARIANT */
  .categories .carouselItem {
    min-width: 110px;
    max-width: 110px;
  }
}

@media screen and (max-width:600px) {
  .carouselWrap {
    margin-left: -.5rem;
    margin-right: -.5rem;
  }

  .carouselWrap.sponsored {
    padding: 0.75rem 0.75rem 0.5rem;
  }

  .carouselWrap .controls {
    display: none;
  }
  .scrollLft {
    left: 0;
  }
  .scrollRgt {
    right: 0;
  }
  /* VARIANTS */
  .categories .carouselItem {
    min-width: 64px;
    max-width: 64px;
    margin-right: .25rem;
  }

  .productCards .carouselItem {
    border: 1px solid transparent;
    min-width: 156px;
    max-width: 156px;
    margin-right: .25rem;
  }
}
