.fullWidthTable {
  width: 100%;
  border-collapse: collapse;
}

.rowWithBorder:first-of-type td {
  border-top: 1px solid #ccc;
}

.cellWithBorder {
  background-color: #fff7;
  padding: 4px;
  border-bottom: 1px solid #ccc;
}
