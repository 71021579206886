/* BundleDealsPage */
.carouselsWrap {
 min-height: 500px;
}

.mixDetails {
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--zrPrimaryVeryDark);
  margin: .5rem 0 0;
}

.mixDetails:after {
  content: "";
  display: block;
  border-bottom: 2px solid #f72c5a;
  margin-top: 1rem;
}

.boomIcon {
  font-size: 48px;
  line-height: 100%;
  vertical-align: -6px;
  margin-right: 6px;
}

@media screen and (max-width:600px) {
  .mixDetails {
    font-size: 1.25rem;
  }
  .boomIcon {
    font-size: 36px;
    vertical-align: -3px;
    margin-right: 4px;
  }
}

/* BundleDealsCarousel */
.seeAllButton {
  margin: 2rem auto;
  min-width: auto;
  padding: .875rem 1.5rem;
}

.notFoundWrapper {
  padding: 2rem;
}

/* BundleDealLinksforCategory */
.categoryBundleCallout {
  max-width: 880px;
  box-sizing: border-box;
  padding: 12px 24px;
  margin: 2rem auto 0;
  color: #fff;
  background: #6b4674;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #6b4674, #f72c5a);
  background: linear-gradient(to left, #6b4674, #f72c5a);
  text-shadow: 0 0 2px #000;
  border-radius: 8px;
  font-weight: 600;
  font-size: 1.25rem;
}

.calloutHeader {
  margin-bottom: .25rem !important;
}

.calloutMessage {
  font-size: 1rem;
}

.bundleLinks {
  display: flex;
  flex-wrap: wrap;
  margin-top: .5rem;
}

.bundleLink {
  cursor: pointer;
  font-size: 1.125rem;
  text-decoration: underline;
  white-space: nowrap;
  margin: 0 16px 12px 0
}

@media  screen and (min-width:600px) {
  .categoryBundleCallout {
    margin: 3rem auto 0;
  }
  .bundleLink {
    font-size: 1.5rem;
    margin: 0 24px 12px 0;
  }
}
