.noOrdersMsg {
  margin: 2rem 0;
  text-align: center;
  font-size: 1.2rem;
}

.shopLink {
  cursor: pointer;
  font: var(-zrFontSemi);
  color: var(--zrPrimaryDark);
}
.shopLink:hover {
  text-decoration: underline;
}

.orderWrap {
  padding-top: 1.5rem;
  max-width: 928px;
  margin: 0 auto;
}

.orderDetails {
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: .5rem;
  margin: 1rem 0 2rem;
}

/* Canceled order styles */
.statusHelp dt.cancelTerm,
.canceled .status,
.canceled .orderDetailHdr svg {
  color: #aa1212;
}
.canceled .orderTotal {
  color: #595959;
  text-decoration: line-through;
}

.orderDispensary {
  font: var(--zrFontBold);
  font-size: 1.5rem;
  margin: 0 0 .5rem;
  color: var(--zrPrimaryDark);
  cursor: pointer;
}

.orderDispensary:hover {
  text-decoration: underline;
}

.orderDetailHdr {
  display: grid;
  color: #595959;
}

.headerNote {
  font-size: .75rem;
  vertical-align: middle;
  font-weight: normal;
  padding: 0 .5rem;
}

.orderDetailHdr .col1 { grid-column: 1;}
.orderDetailHdr .col2 { grid-column: 2;}
.orderDetailHdr .col3 { grid-column: 3;}
.orderDetailHdr .col4 { grid-column: 4;}

.orderDetailHdr .orderDetailHdrItem {
  padding:.75rem 1rem;
  background-color: rgba(0,0,0,.05);
}

.orderDetailHdr .orderDetailHdrItem.statusRow { background-color: #fff; text-transform: uppercase;}
.orderDetailHdr .statusRow.col4 {
  grid-column: 4/5;
}

.orderItemsHeader,
.orderDetailHdrLbl {
  font: var(--zrFontSemi);
  font-size: 15px;
  text-transform: uppercase;
  margin:0 0 .5rem;
  white-space: nowrap;
  color: #595959;
}

.orderDetailHdrVal {
  color: #448607;
  white-space: nowrap;
  font: var(-zrFontSemi);
  font-size: 14px;
}

.orderDetailHdrVal .paymentLabel {
  font: var(--zrFontSemi);
  text-transform: none;
}

.orderDetailHdrVal.deliveryWindow {
  text-transform: none;
}

/* active/previous orders */
.orderSectionHdr {
  color: var(--zrPrimaryDark);
  font: var(--zrFontSemi);
  font-size: 1.125rem;
}

.orderItems {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0 0;
}

.orderItem {
  flex: 1;
  min-width: 25%;
  max-width: 25%;
  margin-bottom: 2rem;
}

.orderedProducts {
  padding: 1rem 1rem 0;
}

.link {
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

/* long order Ids require an early tablet layout */
@media screen and (max-width:1000px) {
  .orderDetailHdr .col3 { grid-column: 3/5;}
  .orderDetailHdr .col4 { grid-column: 1/5;}

  .orderDetailHdr .statusRow.col3 {
    grid-column: 3;
    justify-self: start;
  }
  .orderDetailHdr .statusRow.col4 {
    grid-column: 4;
    justify-self: start;
  }
}

@media screen and (max-width:800px) {
  .orderItem {
    min-width: 33%;
    max-width: 33%;
  }
}

@media screen and (max-width:600px) {
  .noOrdersMsg {
    font-size: 1rem;
  }

  .orderItemsHeader {
    padding: 0 .75rem;
    margin-bottom: 0;
  }

  .orderedProducts {
    padding: 1rem 0 0 0;
  }

  .orderItems {
    display: block;
    margin: 1rem .25rem 0;
  }

  /* items are full width at 600px */
  .orderItem {
    min-width: auto;
    max-width: none;
  }

  .orderDetailHdr .col1 { grid-column: 1/3;}
  .orderDetailHdr .col2 { grid-column: 3/5;}
  .orderDetailHdr .col3 { grid-column: 1/5;} /* long order Id */
  .orderDetailHdr .col4 { grid-column: 1/5;}

  .orderId {
    font-size:.8rem;
  }

  .orderDetailHdr .orderDetailHdrItem {
    padding: .75rem .5rem;
  }

  .orderDetailHdr .statusRow { background-color: #fff; text-transform: uppercase;}
  .orderDetailHdr .statusRow.col3 {
    grid-column: 1/3;
    justify-self: start;
  }
  .orderDetailHdr .statusRow.col4 {
    grid-column: 3/5;
    justify-self: start;
  }

  .orderDetailHdrVal .paymentLabel {
    font-size: .875rem;
  }
}

/* Status Modal */
.statusHelp {
  padding: 0 1rem;
  margin-bottom: 1rem;
}

.status,
.statusHelp dt {
  text-transform: uppercase;
}
.statusHelp dt {
  font: var(--zrFontSemi);
  margin: .5rem 0;
  color: var(--zrPrimaryDark);
}
