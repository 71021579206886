/* This is the banner is positioned over the address prompt
   until an item is carted */
.addressOverlay {
  display: none; /* enabled on specific pages */
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translate(0, -50%);
  box-sizing: border-box;
  height: 40px;
  line-height: 39px;
  max-width: 95%;
  width: 350px;
  white-space: nowrap;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  text-shadow: 0 0 2px #000;
  background: linear-gradient(270deg,#6b4674,#f72c5a);
  padding: 0 40px 0 10px;
  border-radius: 4px;
}

body[data-route="products"] .addressOverlay,
body[data-route="home"] .addressOverlay,
body[data-route="popular"] .addressOverlay {
  display:block;
}

.overlayClose {
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 2px;
  padding: 2px 6px;
}

.priorityOverlay {
  padding: 0 10px;
  width: 326px;
}

.priorityBoltIcon {
  height: 20px;
  margin-right: 3px;
  vertical-align: -4px;
}

.referLink {
  cursor: pointer;
  font-style: italic;
  text-decoration: underline;
  padding-left: 6px;
}

/* See DeliverTo.DELIVER_TO_BAR_THRESHOLD */
@media screen and (max-width:1024px) {
  /* full width for mobile */
  .addressOverlay {
    left: 8px;
    right: 8px;
    width: auto;
    max-width: none;
  }
}
