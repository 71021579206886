/**
 * Globals --zrModalMaxHeightMB and --zrModalMaxHeightDT are
 * relevant to elements with scrolling here.
 */

.card {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 1024px;
  max-width: 100%;
}

.card::-webkit-scrollbar {
  display: none;
}

.productImageWrap {
  position: relative;
  flex: 0 0 360px;
  order: 1; /* e.g. on right side */
  /* overflow: hidden; --breaks deal tooltip */
}

.productImageInner {
  box-sizing: border-box;
  padding: 2.5rem 3rem 1rem;
}

/* Body scrolls in DT resolution */
.productBody {
  flex: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 2.5rem 3rem 2rem;
  max-height: var(--zrModalMaxHeightDT);
  overflow-y: auto;
}
.productBody.nonModal {
  max-height: none;
  border-radius: .5rem;
}

.productName {
  flex: 0;
  color: var(--zrBlack);
  font: var(--zrFontBold);
  font-size: 1.625rem;
  margin:0 0 .25em;
}

/* brand + low stock warning */
.brandGrid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: .5rem 0 .375rem;
}

.brand {
  margin-right: 1rem;
}

.brandLink {
  cursor: pointer;
  font-weight: 600;
  text-decoration: underline;
}
/* Hide partner link on partner pages */
body[data-route="partner"] .brandLink,
body[data-route="brand"] .brandLink {
  cursor: default;
  font-weight: 400;
  text-decoration: none;
}
.fullBrandLink {
  margin: 8px 0;
  font-size: 15px;
  font-style: italic;
}
body[data-route="partner"] .fullBrandLink,
body[data-route="brand"] .fullBrandLink {
  display: none;
}

.productInfo {
  flex: 1;
  margin: 1rem 0;
}

.cartButtonDesktop {
  margin: 0 -2%; /* For the bulk deal content */
}

.cartButtonMobile {
  position: sticky;
  bottom: 4rem;
  display: none;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
  padding: 0 1rem;
  border-top: 1px solid #d7e4eb;
}

.addedWrapper {
  font-weight: normal;
  position: relative;
  margin: 0 0 0 1rem;
}

.productDesc {
  margin: .75rem 0 0;
  line-height: 140%;
}

/* Close modal button for mobile */
.closeButtonWrap {
  margin: 3.25rem 0 1.5rem;
  text-align: center;
}

.closeButton {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  font: var(--zrFontSemi);
  background-color: #fff8;
  padding: 6px;
  border-radius: 4px;
  box-shadow: 0 0 3px #13460188;
}

.closeIcon {
  cursor: pointer;
  height: 1rem;
  background-color: #fff;
  padding: .625rem;
  border-radius: 50%;
  box-shadow: 0 0 3px #13460188;
}

.closeLabel {
  padding: 0 12px;
}

.lowStockWarning {
  font: var(--zrFontSemiItalic);
  font-size: 1rem;
  line-height: 32px; /* for wrapping */
  color: var(--zrTextRed);
  white-space: nowrap;
}

@media screen and (max-width:1024px) {
  .productImageWrap {
    flex: 0 0 320px;
  }
  .productImageInner {
    padding: 2.5rem 1rem 1rem;
  }
}

/**
 * Under tablet width we switch to a stacked non-flex layout
 * and move the scrolling up from .productBody to the outer
 * .card element
 */
@media screen and (max-width:767px) {
  .card {
    display: block;
    max-height: var(--zrModalMaxHeightMB);
    overflow-y: auto;
  }

  .cartButtonDesktop {
    display: none;
  }

  .productBody {
    display: block;
    padding: 1.5rem 2rem 2rem;
    margin-bottom: 63px;  /* for AddToCart, now at bottom */
    min-height: auto;
    max-height: none;
    overflow-y: visible;  /* undo DT body-only scroll */
  }

  .productName {
    font-size: 1.5rem;
  }

  .productDesc {
    font-size: 1rem;
  }

  .productInfo {
    margin: .75rem 0;
  }

  .cartButtonMobile {
    position: fixed;
    bottom: 0;
    left:0;
    right:0;
    display: flex;
  }
  /* Product detail page */
  .cartButtonMobile.nonModal {
    bottom: 60px;
  }
  .cartButtonMobile button {
    margin: .5rem auto; /* reduce 1rem margin */
  }
}

@media screen and (max-width:600px) {
  .productImageInner {
    padding: 1rem;
  }

  .productBody {
    padding: 1.5rem 1rem 2rem;
  }

  .productName {
    font-size: 1.375rem;
  }
  .brand {
    margin-bottom: 12px;
  }

  .productDesc {
    font-size: .9375rem;
    line-height: 145%;
  }
  .qtyLimit {
    font-size: 14px;
    padding: 6px 10px;
    margin: 6px 4px; /* wrapping */
  }
}
