
.accordionWrap {
  margin: 12px 0 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.accordionHeader {
  color: var(--zrPrimaryVeryDark);
  font-size: .875rem !important;
  font-weight: 600;
  margin: 0 !important;
  padding: 0 .5rem;
}

.accordionWrap :global .MuiPaper-root.MuiAccordion-root {
  background-color: #fff8 !important;
}

.accordionWrap :global .MuiAccordionSummary-root {
  min-height: auto;
  padding: 0 12px 0 0;
}

.accordionWrap :global  .MuiAccordionSummary-root.Mui-expanded {
  min-height: auto;
}

.accordionWrap :global .MuiAccordionSummary-content {
  margin: 8px 0;
}

.accordionWrap :global .MuiAccordionSummary-content.Mui-expanded {
  margin: 8px 0;
}

.accordionWrap :global .MuiAccordionDetails-root {
  display: block; /* not flex */
  padding: 0 6px 8px;
  color: var(--zrPrimaryVeryDark);
}

/* expand arrow */
.accordionWrap :global .MuiIconButton-root {
  padding: 0 12px;
}

/* mobile */
@media screen and (min-width:600px) {
  .accordionHeader {
    padding: 0 1rem;
  }
  .accordionWrap :global .MuiAccordionDetails-root {
    padding: 0 14px 8px;
  }
}
