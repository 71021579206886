.cart {
  top: 3rem;
  right: 0;
  position: fixed;
  bottom: 0;
  overflow-y: auto;
  width: calc(100% - 25px); /* box shadow */
}

.cartInner {
  padding: 0 1rem; /* need space for scroll bar on right */
  background-color: #fff;
  margin-bottom: 12rem;  /* better scroll-into-view */ 
}

.cartHeader {
  position: sticky;
  top: 0;
  z-index: 3; /* 3 due to cartItem controls */  
  padding: 1rem 0;
  background-color: #fff;
  font: var(--zrFontBold);
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #191919;
}

.cartDetails { 
  display: flex;
  align-items: center;
  background-color: var(--menuGray);
  font-size: 1rem;
}

.serviceDetails {
  flex: 1;
  font: var(--zrFontBold);
  font-size: .875rem;
  padding: .75rem 1rem;
}

.serviceAddress {
  font: var(--zrFontBold);
  font-size: .875rem;
  margin-top: .5rem;
}

.cartItems {
  margin: 1rem 0;
  padding: 0;  
  list-style-type: none;
}

/* The suggestion carousel */
.suggestWrap {
  margin: 4rem 0 0;
}

@media screen and (max-width:480px) {
  .cart {
    width: 100%;
  }
  .cartHeader {
    padding-top: 0;
  }  
  .cartInner {
    padding: .5rem 1rem 6rem;
  }
}
