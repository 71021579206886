.emptyBag {
  position: fixed;
  width: 100%;
  margin-left: -10px;
  color: #9B9B9B;
  text-align: center;
  line-height: 1.25rem;
}

.emptyBag.inGrid {
  position: relative;
}

.bagImage {
  display: inline-block;
  max-width: 100%;
  max-height: 150px;
  margin: 3rem 0 1rem; 
}

@media screen and (max-width: 480px) {
  .emptyBag {
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-left: -10px;
  }
}
