/* for positioning images */
.imageOuter,
.imageWrap {
  position: relative;
}
.imageWrap {
  min-height: 120px; /* prevent page jump */
}

.productImage {
  display: block;
  margin: 0 auto .25rem; /* top margin affects whitespace top-of-card! */ 
  border-radius: .25rem; 
  max-width: calc(100% - 1rem);
  max-height: 150px;
}

/* image coming soon overlay */
.placeholderMessage {
  position: absolute;
  top: 82%;
  left: 10%;
  right: 10%;
  transform: translateY(-50%);
  border-radius: 24px;
  text-align: center;
  font: var(--zrFontSemi);
  font-size: .8125rem;
  line-height: 1.675rem;
  overflow: hidden;
}

/* NOTE: This is a shared class for all ~3 label instances */
.missingImageLabel {
  text-align: center;
  color: #000;
  background-color: #85c1f6bb;
  composes: ellipses from '../common/mixins.css'; 
}

/* small suggested product card */
.placeholderMessage.missingImageSuggested {
  font-size: .75rem;
  left: 6%;
  right: 6%;
}  

@media screen and (max-width:600px) {
  .productImage {
    max-height: 100%;
    margin: 0 auto 0.25rem;
  }
  .placeholderMessage {
    left: 11%;
    right: 11%;
    font-size: .625rem; /* 10px */
    line-height: 1.5rem;
  }    
}
