/* Common, HavingTrouble */
.alert {
  margin: 1.5rem 0 0;
}

.alertHdr {
  font: var(--zrFontSemi);
  margin: 0 0 .25rem;
}

.link {
  font-weight: 600;
  color: var(--zrPrimaryDark);
  margin: 0 .25em;
  white-space: nowrap;
}

/* CartWarnings */
.cartWarnings {
  margin: 1.5rem 0 0;
}

/* MUI xs */
@media screen and (max-width: 599.95px) {
  .cartWarnings {
    margin: 1rem 0 0;
    padding: 2px 12px 6px !important;
  }
}

.warningMsg {
  font-weight: 600;
  margin-bottom: 4px;
}

.warning {
  padding: 0 0 .5rem 0;
  text-align: left;
}
.warning:last-of-type {
  padding: 0;
}

/* Minimum Order Warnings */
.minimumWarning {
  margin-top: 1rem;
}

.minimumBody {
  text-align: left;
}

.minimumMsg {
  font-size: 15px; /* MUI base is 14px */
}

.minimumFootNote {
  margin-top: 8px;
  line-height: 120%;
}

.minimumAmount {
  margin-left: 3px;
  font-weight: 600;
  white-space: nowrap;
}

/* VerifyCartReminderProducts: Abandoned Cart message for Home page */
.cartNotice {
  position: relative;
  max-width: 600px;
  box-sizing: border-box;
  margin: 1rem auto;
  background-color: var(--zrLightBackground);
  border-radius: 4px;
  padding: 10px 12px 8px;
  color: var(--zrPrimaryVeryDark);
}

.closeIcon {
  cursor: pointer;
  position: absolute;
  z-index: 2;
  right: .5rem;
  top: .5rem;
  height: 1rem;
  background-color: #fff;
  padding: 6px;
  border-radius: 50%;
  box-shadow: 0 0 3px #13460188;
}

.cartLoginPrompt {
  text-align: center;
  margin: 16px 0 12px;
}

.cartLoginLink {
  cursor: pointer;
  font: var(--zrFontSemiItalic);
  text-decoration: underline;
}

.cartAlertWrap {
  margin: 4px 0;
  border: 1px solid #000;
  background-color: #fff;
  border-radius: 4px;
  padding: 1rem;
}

.cartNoticeHdr {
  text-align: center;
  font: var(--zrFontBoldItalic);
  color: #000;
  font-size: 1.25rem;
  margin: 0 0 8px;
}

.cartListHdr {
  color: #000;
  font-weight: 600;
  margin-bottom: 6px;
}

.cartList {
  margin: 4px 0;
  padding: 2px 0;
  border-radius: 4px;
  border: 1px solid #000;
  color: #000;
  background-color: #fff;
  max-height: 90px; /* 3 items */
  overflow-y: auto;
}

.cartList li {
  display: flex;
  column-gap: 8px;
  justify-content: space-between;
  font-weight: 600;
  font-size: .875rem;
  padding: 3px 8px;
}

.cartList li .cartItem {
  flex: 1;
}

.cartList li .itemStatus {
  flex: 0;
  min-width: 72px;
  white-space: nowrap;
  text-align: right;
}

.cartList li.itemUnavailable .itemStatus {
  color: var(--zrTextRed);
  font-style: italic;
}

.cartLinks {
  display: flex;
  justify-content: space-between;
  font-size: .875rem;
  padding-top: 6px;
  text-align: right;
}

.cartLink {
  cursor: pointer;
  font-weight: 600;
  white-space: nowrap;
  display: block;
  align-items: center;
  text-align: right;
  margin: 4px 0;
}

.cartLink svg {
  vertical-align: -6px;
}
