.thresholds {
  box-sizing: border-box;
  padding: 2px 4px;
}

.threshold {
  display: 'block',
}

.savings {
  color: var(--zrTextRed);
  font-style: italic;
  font-weight: 600;
  margin-left: 8px;
}

.mixMatchMsg {
  padding: 0 4px;
  margin: 8px 0 12px;
}
