/* NotifyMeEmailInput */

.promptWrap {
  margin: 1rem 1rem 1.5rem;
  font-size: 1rem;
  line-height: 1.33rem;
  max-width: calc(100vw - 2rem);
}

/* MailChimp subscribe email input */
.promptWrap input[type="email"] {
  font-size: 1rem;
  width: calc(100% - 12px);
  box-sizing: border-box;
  border-radius: 3px;
  padding: 1rem 1rem 10px 2.5rem;
  outline: none;
  background-color: #f6f7f8;
  border-width: 0 0 2px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.42);
} 

.promptWrap input[type="email"]:focus {
  border-color: var(--zrPrimaryDark);
}

/* mail icon */
.emailWrap {
  position: relative;
}

.emailWrap :global .MuiSvgIcon-root {
  position: absolute;
  top: 50%;
  left: .25rem;
  transform: translateY(-45%);
}

.notifyButton {
  padding: .75rem .875rem;
}

.responseMsg {
  margin: .5rem 0 0;    
}

.spinWrap {
  height: 36px;
  text-align: center;
}

@media screen and (max-width:480px) {
  .promptWrap {
    margin: 0 0 1.5rem;
  }
  .promptWrap input[type="email"] {
    box-sizing: border-box;
    width: 100%;
  }
  .emailWrap {
    margin: .5rem 0 0;
  }
  .notifyButton {
    margin-top: .75rem;
    min-width: 100%;
  }
}
