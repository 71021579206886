.heroSection {  
  padding: 1.5rem 0 0 0;
}

.heroWrap {
  position: relative;
  min-height: 122px;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  border-radius: .5rem;
  box-sizing: border-box;
  padding: 1rem 1.25rem;
  background: #6b4674;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #6b4674, #f72c5a);
  background: linear-gradient(to left, #6b4674, #f72c5a);
}

.header {
  color: #fff;
  background-color: #4f0561;
  font-size: 18px;
  font-style: italic;
  font-weight: 600;
  line-height: 126%;
  padding-top: 23px;
  box-sizing: border-box;
  height: 90px;
  width: 90px;  
  border-radius: 45px;
  text-align: center;
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 1;
  transform: rotate(-7deg);
  text-shadow: 0 0 1px #000;
  box-shadow: 0 0 24px #d77cec;
}

.brands {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-width: 86%;
  margin: 0 auto;
  padding-bottom: 0;
  justify-content: space-around;
}

.brand {
  font-size: 36px;
  font-weight: 600;
  line-height: 130%;
  color: #fff;
  text-shadow: 0 0 5px #000;
  margin: 6px 0 18px;
  white-space: nowrap;
  transform: rotate(-5deg);
  composes: ellipses from '../common/mixins.css';
}
.brands[data-brand-count="1"] .brand {
  font-size: 48px;
}

@media screen and (max-width:1024px) {
  .brand {
    font-size: 32px;
  }
  .brands[data-brand-count="1"] .brand {
    font-size: 40px;
  }
}    

@media screen and (max-width:800px) {
  .heroSection {
    padding: 0;
    margin: 0 -1rem;
  }
  .heroWrap {
    min-height: 100px;
  }
  .header {
    font-size: 14px;
    height: 70px;
    width: 70px;  
    border-radius: 35px;
    padding-top: 19px;
  }
  .brands {
    padding-bottom: 0;
    justify-content: flex-end;
    text-align: right;
  }  
  .brand {
    flex-basis: 66%;
    font-size: 28px;
    margin: 2px 0;
  }
  .brands[data-brand-count="1"] .brand {
    font-size: 32px;
  }
  .brand:first-of-type {
    margin-top: 4px;    
  }
  .brands.shortName .brand:first-of-type {
    margin-top: 16px;    
  }
  .brand:last-of-type {
    margin-bottom: 0;  
  }
}

@media screen and (max-width:480px) {
  .heroWrap {
    border-radius: 0;
  }
  .brand {
    padding-left: 4.5rem;
  }
  .brands[data-brand-count="1"] .brand {
    font-size: 28px;
  }
}    
