.noDeliveryModal {
  width: 450px;
  max-width: 100%;
  margin: 0 0 .75rem;    
}

.emailHeader {
  margin: 1rem;  
  text-align: center;
  font: var(--zrFontSemi);
  line-height: 140%;
  font-size: 1.125rem;
}

.subscribedHeader {
  display: flex;
  margin: 1rem;  
}

.subscribedIcon {
  flex: 0;
  flex-basis: 32px;
}

.subscribedMsg {
  flex: 1;
  font: var(--zrFontSemi);
  line-height: 140%;
}

.continueMsg {
  margin-top: 12px;
  text-align: center;
}

.browseLink {
  cursor: pointer;
  font: var(--zrFontBold);
  color: var(--zrPrimaryDark);
}

.browseLink:hover {
  text-decoration: underline;
}

@media screen and (max-width:480px) {
  .emailHeader {
    margin: 1rem 0 .5rem;  
  }
}
