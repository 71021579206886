/** HEADERS */
/* Do not add Top Margin to headers! */
.header {
  position: relative;
  font: var(--zrFontBold);
  font-size: 2rem;
  margin: 0 0 1.25rem;
}
  
.header.large  {
  font-size: 2rem;
  margin: 0 0 1.25rem;
}

.header.mediumStatic,
.header.medium {
  font-size: 1.5rem;
  margin: 0 0 1rem;
}

.header.small {
  font-size: 1.25rem;
  margin: 0 0 .75rem;    
}

.header.clickable:hover {
  text-decoration: underline;
  cursor: pointer;
}

/* Search callout in header */
.searchCallout {
  cursor: pointer;
  position: absolute;
  top: -2px;
  right: -9px;
  font-size:15px;
  line-height:100%;
  font-weight: 400;
  font-style: italic;
  color:#000;
  border: 2px solid #000;
  border-radius: 4px;
  padding: 5px 6px 5px 8px;
}

.searchIcon {
  vertical-align: -3px;
  margin-left: -1px;
}

.newFeature {
  position: absolute;
  right: -6px;
  top: -12px;
  color: #fff;
  background-color: var(--zrTextRed);
  font-size: 10px;
  line-height: 100%;
  font-weight: 600;
  font-style: italic;
  padding: 3px 6px 4px;
  border-radius: 12px;
}

@media screen and (min-width:600px) {
  .searchCallout {
    top: -1px;
    font-size: 17px;
    padding: 5px 9px 5px 11px;
  }
  .newFeature {
    right: -10px;
  }
  .searchIcon {
    height: 18px;
  }
}

@media screen and (min-width:1300px) {
  .searchCallout {
    right: 0;
  }
}
/* END Search callout in header */

/* tablet sizing at 800 */
@media screen and (max-width: 800px) {
  .header.large  {
    font-size: 1.75rem;
  } 
}

/* start mobile sizing at 600 */
@media screen and (max-width: 600px) {
  .header.large {
    font-size: 1.5rem;
  } 
  
  .header.mediumStatic {
    font-size: 1.375rem;
  }

  .header.medium {
    font-size: 1.125rem;   /* 1.1875 = 19px */
  }
  
  .header.small {
    font-size: 1.125rem;
    margin: 0 0 .25rem;    
  }  
}
  