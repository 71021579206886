/* map grid on Desktop */
.mapGrid {
  display: flex;
  overflow: hidden;
}

.mapWrapper {
  flex: 1;
  order: 2;
  background-color: #f2f3f4;
}

.mapWrapper :global #map {
  margin: 0 !important;
}

/* Hide footer on Desktop */
body[data-route="orderConfirmation"] :global #footer { display: none;}
body[data-route="orderConfirmation"] :global #layout { padding-bottom: 0;}

/* DD uses 640px for content column in Desktop */
.orderConfirmationWrap {
  max-width: 640px;
  margin: 0 auto;
  overflow-y: scroll;
  box-shadow: transparent 0px 0px 0px 1px inset, rgb(0 0 0 / 15%) 0px 1px 16px;
}
/* end map grid stuff */

.orderConfirmationWrap {
  background-color: #fff;
  position: relative;
  z-index: 1;
  padding: 0 0 4rem;
}

.orderSubHead {
  margin-top: .5rem;
  font: var(--zrFontSemi);
}

.telLink {
  display: inline-block;
  margin: .25rem 0;
  text-decoration: none;
  color: #000;
  font: var(--zrFontSemi);
}

/* show phone icon on mobile only */
.mobileCall {
  display: none;
  font-size: inherit;
}

.mobileCall svg {
  vertical-align: -4px;
  margin-right: 4px;
  color: var(--zrPrimaryDark);
}

.orderSection {
  border-top: 1px solid #ccc;
  padding: 1rem;
}

.sectionHdr {
  font: var(--zrFont);
  font-size: 1.25rem;
  margin: 0 0 .75rem;
}

.sectionContent {
  line-height: 133%;
}

.detailsGrid {
  display: flex;
  flex-wrap: wrap;
}

.detailItem {
  flex: 1;
  min-width: 50%;
  margin-bottom: .75rem;
}

.detailLabel {
  font: var(--zrFontSemi);
  margin-bottom: .25rem;
}

/* For the Order Instructions section */
.updateItems {
  margin: 1rem 0 0;
}
.updateItem {
  display: flex;
  line-height: 133%;
}
.updateItem svg {
  margin-top: 1rem;
}
.updateItem p {
  margin-left: .75rem;
}

.cartItems {
  display: block; /* single col */
}

/* order items */
.cartItem {
  display: flex;
  padding: .5rem;
  border: 1px solid #ccc;
  border-radius: .5rem;
  box-sizing: border-box;
  margin: 0 0 .5rem;
}

.itemDetails {
  flex: 4;
  display: flex;
  align-items:flex-start;
  flex-wrap: wrap;
  margin: 0 .5rem 0 0;
}

.topDetails {
  display: flex;
}

.itemCount {
  flex: 0;
  min-width: 28px;
}

.itemTitle {
  font: var(--zrFontSemi);
}

.itemPrice {
  align-self: flex-end;
  flex-basis: 100%;
}

.itemCount,
.itemTitle,
.itemPrice {
  padding: .25rem 0;
}

.imageWrap {
  flex: 2;
}

.itemImg {
  flex: 0;
  display: block;
  margin: 0 auto .5rem;
  max-width: 100%;
  max-height: 80px;
  border-radius: .5rem;
}

.buttonWrap {
  margin: 1rem 0;
  text-align: center;
}

.surveyWrap {
  margin: 0 1rem;
}

/* AdditionalCustomerAsks */
.reviewLink {
  padding: 1rem 1rem 0;
}
.emphasize {
  font-weight: 600;
  font-style: italic;
}

/* undo map grid just like DD */
@media screen and (max-width:960px) {
  .surveyWrap {
    margin: 0;
  }

  /* UNDO THE MAP GRID */
  .mapGrid {
    display: block;
    height: auto;
    overflow: visible;
  }
  .mapWrapper {
    flex:1;
    order: 2;
    height: 225px;
  }

  .orderConfirmationWrap {
    box-shadow: none;
    max-width: none;
    padding: 0 0 4rem;
  }

  /* restore footer */
  body[data-route="orderConfirmation"] :global #footer { display: block;}

  /* END UNDO GRID */
}

/* Smaller text mostly */
@media screen and (max-width:800px) {
  .orderSubHead {
    font-size: 1rem;
  }
  .detailLabel,
  .detailValue {
    font-size: .875rem;
  }
}

/* Remove cart item grid */
@media screen and (max-width:600px) {
  .cartItems {
    display: block;
  }
  .cartItem {
    min-width: auto;
    max-width: none;
  }
}

/* smaller text mostly */
@media screen and (max-width:480px) {
  .orderSubHead,
  .sectionContent,
  .itemCount,
  .itemTitle,
  .itemPrice {
    font-size: .875rem; /* 14px per DD */
  }
  .sectionHdr {
    font-size: 1.375rem; /* 22px per DD */
  }
  .mobileCall {
    display: inline;
  }
}

/* clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 0px 80%); */
