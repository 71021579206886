/**
 * Currently the Product Request Survey is displayed
 * in an accordion using formOnly=true
 */
.surveyWrap {
  text-align: center;
  margin: 6rem 0 0;
}

/* The accordion version */
.surveyWrap.formOnly {
  margin: 0;
}

.surveyWrap.howHeardSurvey {
  margin: 2rem auto 5rem;
  max-width: 450px;
}

.surveyForm {
  display:inline-block;
  max-width: 100%;
  width: 880px;
  box-sizing: border-box;
  padding: 1.375rem 2rem 1.25rem;
  border-radius: 8px;
  background-color: #a9d6fc;
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(220,239,255,1) 0%, #a9d6fc 78%);
}

.formOnly .surveyForm {
  padding-top: 0;
  border-radius: 0 0 8px 8px;
  background-color: inherit;
  background: inherit;
}

.surveyForm .formHeader {
  text-align: left;
  color: var(--zrPrimaryVeryDark);
  font-style: italic;
  line-height: 130%;
}

.howHeardSurvey .surveyForm .formHeader {
  text-align: center;
}

.surveyForm .formLegend {
  text-align: left;
  color: var(--zrPrimaryVeryDark);
  font-weight: 600;
}

.surveyForm .gridItems {
  align-items: flex-end;
  justify-content: space-between;
  margin: 0 -1rem;
}

.surveyForm .gridItem {
  flex: 1;
  padding: 0 1rem !important;
  margin-top: .75rem;
}

.surveyForm .textInput {
  width: 180px;
  box-sizing: border-box;
  margin: 0;
}

.surveyForm .textInput :global .MuiInput-input {
  padding: 10px 8px;
  background-color: #fff;
  border-radius: 4px;
}

.surveyForm .categorySelectWrap {
  display: inline-flex;
}

.surveyForm :global .MuiSelect-select {
  width: 180px;
  box-sizing: border-box;
  font-size: 1rem;
  padding: 10px 24px 8px 10px;
  border-radius: 4px;
  background-color: #fff !important;
  text-align: left;
}

.surveyForm :global .Mui-focused .Mui-selectMenu {
  background-color: #fff;
}

.surveyForm .option {
  text-align: left;
}

.surveyForm .submitButton {
  width: 180px;
  padding: 10px 32px;
}

/* howHeardSurvey input widths - desktop */
.howHeardSurvey .surveyForm :global .MuiSelect-select {
  width: 100%;
}
.howHeardSurvey .surveyForm .textInput,
.howHeardSurvey .surveyForm .submitButton {
  width: 100%;
}
.howHeardSurvey .surveyForm .selectFieldset {
  width: 100%;
  box-sizing: border-box;
}

.surveyForm .submitButton:disabled {
  color: #888;
  background-color: #ddd !important;
}

.successAlert {
  margin-top: 18px;
  background-color: rgb(237 247 237 / 40%) !important;
  text-align: left;
}

/* Accordion component */
.accordionWrap {
  margin: 6rem auto 0;
  max-width: 880px;
  box-sizing: border-box;
}

@media  screen and (max-width:600px) {
  .accordionWrap {
    margin: 5rem auto 0;
  }
}

/* carousel header */
.accordionTitle {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.accordionHeader {
  color: var(--zrPrimaryVeryDark);
  font-size: 1.125rem !important;
  font-style: italic;
  margin: 0 !important;
  padding-left: 1rem;
}

.accordionWrap :global .MuiAccordionDetails-root {
  display: block;
  padding: 0;
}

/* MUI accordion overrides - rounded corners and bg color */
.accordionWrap :global .MuiPaper-root.MuiAccordion-root {
  background-color: #deeefd;
}

.accordionWrap :global .MuiPaper-root.MuiAccordion-root.Mui-expanded {
  background-color: #a9d6fc;
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(220,239,255,1) 0%, #9ad0fd 66%);
}

.accordionWrap :global .MuiAccordion-rounded:last-child {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.accordionWrap :global .MuiAccordion-rounded:first-child {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

/* tablet */
@media screen and (max-width:1000px) {
  .accordionHeader {
    padding-left: 0;
  }
  .surveyForm {
    min-width: 80vw; /* for flex */
    padding: 1.375rem 1rem 1.25rem;
  }
  .howHeardSurvey .surveyForm {
    min-width: auto;
  }
  .surveyForm .categorySelectWrap {
    display: flex;
  }
  .surveyForm :global .MuiSelect-select {
    width: 100%;
    min-width: 36vw;
  }
  .surveyForm .textInput {
    width: 100%;
    min-width: 36vw;
  }
  .surveyForm .submitButton {
    width: 100%;
    min-width: 36vw;
  }
}

/* mobile */
@media screen and (max-width:600px) {
  .accordionHeader {
    padding: 0 8px;
  }
  .surveyWrap {
    padding: 0 12px;
  }
  .surveyWrap.formOnly {
    padding: 0;
  }
  .surveyForm {
    display: block;
    width: auto;
    padding: 1.375rem 1.5rem 1.25rem;
  }
  /* Accordion version is missing some parent elements */
  .formHeader,
  .surveyForm .formHeader {
    text-align: center;
  }
  .formHeader .hdrBig,
  .surveyForm .formHeader .hdrBig {
    font-size: 1.2em;
  }
  .formOnly .formHeader {
    font-size: 1.5rem;
  }
  .surveyForm .gridItems {
    display: block;
  }
  .gridItems {
    display: block;
  }
  .gridItem {
    margin-bottom: 8px !important;
  }
  .surveyForm :global .MuiFormControl-root {
    display: flex;
    width: 100%;
    box-sizing: border-box;
  }
  .surveyForm :global .MuiSelect-select {
    width: 100%;
    box-sizing: border-box;
  }
  .surveyForm .submitButton {
    width: 100%;
    margin-top: 8px;
  }
}

@media screen and (max-width:480px) {
  .howHeardSurvey .formHeader {
    font-size: 20px;
  }
}

/* ProductRequestButton for ReOrder page */
.btnSuccessAlert {
  margin-top: 12px;
  text-align: left;
}

.btnSubmitButton {
  width: 100%;
  margin: 12px 0 0;
  padding: 12px 32px;
}
