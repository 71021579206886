/** BUTTONS */

.button {
  cursor: pointer;
  width: auto;
  min-width: 50%;
  margin-top: 1rem;  
  background-color: var(--zrDarkBlue);
  color: #fff;
  padding: .875rem 3rem;
  font: var(--zrFontBold);
  border: none;
  border-radius: 4px; /* 20px */
  white-space: nowrap;
  composes: ellipses from '../common/mixins.css';  
}
.primaryWidth { /* For DetourButton */
  min-width: 50%;
  padding: .875rem 3rem;
}

button.button:focus {
  outline: none;
}

/* Never add hover behavior to mobile */
@media screen and (min-width:600px) {
  .basicButton:hover {
    border: 1px solid #000;
  }
}

button.disabledButton {
  cursor: default;
  background-color: #d3d3d3 !important; /* overwrite theme! */
  color: #fff;
}

button.disabledButton:hover {
  box-shadow: none;
}

/* for DisabledButtonWithReason */
.disabledMsg {
  margin-top: 0;
  border-radius: .25rem;
  overflow: hidden;
  font: var(--zrFontSemi);
  font-size: .875rem;
  line-height: 133%;
}

.disabledMsg :global .MuiAlert-root {
  padding: .25rem 1rem;
}

/* ButtonWithPrice  variant */
.priceButton {
  display: flex;
}

.priceButton .buttonText {
  padding: 0 .25rem;
  text-align:left;
  flex: 10;  
}

.priceButton .buttonPrice {
  padding: 0 .25rem;
  flex: 1;  
}

.basicButton {
  color: #333;
  width: auto;
  margin-top: 0;
  min-width: auto;
  padding: .5rem 1rem;
  font: var(--zrFontSemi);
  background-color:#c4dcee;
  border: 1px solid #999;
}
/* accessibility */
.basicButton:focus {
  border: 1px solid #000;
}  

.detourButton {
  cursor: pointer;
  color: #000;
  background-color: transparent;
  font: var(--zrFontBold);
  border: 2px solid #000;
  border-radius: .25rem;
  padding: .75rem 2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media screen and (min-width:600px) {
  .detourButton:hover {
    color: #fff;
    background-color: #000;
  }
}

/* start mobile button sizing at 600 */
@media screen and (max-width:600px) {
  .primaryWidth { /* For DetourButton */
    min-width: 75%;
    padding: .75rem .75rem;
  }
  .button {
    min-width: 75%; /* see place order buttons */
    padding: .875rem .75rem; 
  }  
}
