/* SlotChangeModalAlert */
.newWindow {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 150%;
  text-align: center;
}

@media screen and (max-width:600px) {
  .newWindow {
    margin-bottom: 6px;
  }
}

.timeslotWrap {
  min-height: 200px;
}

.selectedSlotLabel {
  font: var(--zrFontBold);
  color: #000;
  margin: 0 0 1rem;
}

.fldSet {
  border: none;
  position: relative;
  padding: 0;
  margin: 0;
}

/* START Toggle expired slots feature */
.toggleBtn {
  cursor: pointer;
  background-color: #1879c324;
  color: var(--zrPrimaryVeryDark);
  margin: 0 0 12px;
  padding: 12px 2px;
  border-radius: 4px;
  font-size: 1rem;
  text-align: center;
  white-space: nowrap;
}

.showMsg,
.showAll .hideMsg {
  display: block;
}
.hideMsg,
.showAll .showMsg {
  display: none;
}
.fldSet[data-is-expired="true"] {
  display: none;
}
.showAll .fldSet[data-is-expired="true"] {
  display: block;
}
.noWindowsMsg {
  color: var(--zrPrimaryVeryDark);
  font-style: italic;
  font-weight: 400;
  text-align: center;
  margin-bottom: 8px;
}
/* END Toggle expired slots feature */

.fldSet input {
  position: absolute;
  left: .5rem;
  top: 50%;
  transform: translateY(-50%);
}

.fldSet label {
  cursor: pointer;
  display: block;
  padding: .75rem .5rem .75rem 2rem;
}

.fldSet.selected label {
  cursor: default;
  background-color:#ededed;
}

.fldSet.prioritySlot.selected label {
  background-color: inherit;
}

.prioritySelected,
.priorityChooseBtn,
.fldSet span.selected,
.fldSet label span.chooseButton {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  padding: 4px 8px;
  color: var(--zrPrimaryDark);
  font: var(--zrFontBold);
  font-size: .875rem;
  text-transform: uppercase;
  border: 2px solid var(--zrPrimaryDark);
  border-radius: .25rem;
  min-width: 6rem;
  text-align: center;
}

.fldSet.prioritySlot {
  margin: 12px 0 2px;
  position: relative;
  min-height: 44px;
  border: none;
  padding: 0;
  border-radius: 4px;
  color: #fff;
  background: linear-gradient(270deg, #6b4674dd, #f72c5add);
}
/* Disabled Priority Slot */
.fldSet.prioritySlot.disabledFldSet {
  opacity: .6;
  margin: 0 0 12px;
}

.prioritySlot .priorityChooseBtn {
  background-color: #fff;
  border-color: #000;
  text-shadow: none;
  color: #000;
}

.prioritySlot label {
  font-size: 104%;
  font-weight: 600;
  text-shadow: 0 0 2px #000;
}

.prioritySlot .prioritySelected {
  color: #fff;
  background-color: #000;
  border-color: #000;
  box-shadow: 0 0 3px #f72c5add;
}

.priorityNote {
  font-weight: 400;
  font-size: 12px;
  margin-bottom:4px;
  padding-left: 32px;
}

.priorityDisclaimer {
  font-style: italic;
}

.fldSet label span.notAvailable {
  display: none;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  padding: 4px 8px;
  font: var(--zrFontSemi);
  text-align: center;
}

.fldSet label span.soldOut {
  color: var(--zrPrimaryDark);
  font: var(--zrFontSemiItalic);
}

.fldSet .discount {
  display: inline-block;
  vertical-align: 1px;
  margin: 0 0 0 12px;
  font: var(--zrFontSemiItalic);
  font-size: 13px;
  line-height: 100%;
  padding: 3px 8px 5px;
  border-radius: 12px;
  color: #fff;
  background-color: var(--zrTextRed);
}

@media screen and (max-width:600px) {
  .fldSet .discount {
    position: absolute;
    z-index: 1;
    left: 130px;
    top: -4px;
  }
}

.fldSet.disabledFldSet .discount {
  display: none;
}

/* Disabled time slot styles */
.fldSet.disabledFldSet {
  opacity: .8;
}
.fldSet.disabledFldSet label {
  cursor: default;
}
.fldSet.disabledFldSet label span.chooseButton {
  display: none;
}
.fldSet.disabledFldSet label span.notAvailable {
  display: block;
}

@media screen and (max-width:600px) {
  .fldSet.disabledFldSet label span.notAvailable {
    font-size: 14px;
  }
}

.fldSet span.selected {
  font-style: normal;
  color: #fff;
  background-color: var(--zrPrimaryDark);
}

/* Suppress hover styles on mobile */
@media screen and (min-width:600px) {
  .fldSet:hover {
    background-color:#ededed;
  }
  .fldSet.disabledFldSet:hover,
  .fldSet.prioritySlot:hover {
    background-color: inherit;
  }
}
