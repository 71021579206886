.linksSubHeader {
  font-weight: 400;
  font-size: .75em;
  line-height: 133%;
}

.newProductImage {
  display: inline;
  vertical-align: -7px;
  margin-right: 5px;
}

.newProductsWrapper {
  margin-top: 2rem;
}

.newSinceDate {
  margin: 0 .25em;
  font-weight: 600;
  white-space: nowrap;
}

.linkGrid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 1rem;
  width: 100%;
  margin: 16px 0;
}

@media screen and (max-width:600px) {
  .newProductsWrapper {
    margin-top: 1.25rem;
  }
  .linkGrid {
    column-gap: 10px;
  }
}

.linkGridItem {
  flex: 0;
  flex-basis: 48%;
  max-width: 240px;
  box-sizing: border-box;
  cursor: pointer;
  font: var(--zrFontBold);
  text-decoration: underline;
  text-align: center;
  margin-bottom: 10px;
  padding: 8px 2px;
  border-radius: 4px;
  border: 2px solid #000;
}

.newProductsWrapper {
  position: relative;
}

.newProductsScrollTarget {
  position: absolute;
  z-index: -1;
  top: -104px;
  left: -20px;
  width: 0px;
  height: 0px;
}
