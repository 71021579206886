/* LAYOUT */
.carouselOuter {
  position: sticky;
  left: 0;
  right: 0;
  top: 3rem;
  background-color: #fff;
  margin: .5rem auto 0;
  max-width: 1316px; /* 1300 + 1rem */
  z-index: 2; /*  above carousel controls */
}

@media screen and (max-width:600px) {
  .carouselOuter {
    margin: .5rem -.5rem 0;
  }
}

/* Intersection Observer: Used to determine when to collapse filter, show nav icons etc. */
.pxl {
  position: absolute;
  z-index: 2;
  top: 0;
  height: 2px;
  width: 2px;
}

.scrollXPixelLeft {
  left: 0;
}

.scrollXPixelRight {
  right: 0;
}

/* Remove category images when filter is sticky */
/* 36px height is mobile and desktop */
.carouselOuter[data-is-sticky=true] .carousel {
  align-items: center;
  height: 36px; /* This height impacts scroll calcs in the jsx */
  overflow-y: hidden; /* prevent vertical scroll of carousel items! */
}

.carouselOuter[data-is-sticky=true] .carouselItem {
  font-size: .875rem;
  padding: 0 0.25rem;
  margin: 0 0.125rem;
  flex: 1;
  border-radius: 6px;
}

.carouselOuter[data-is-sticky=true] .homeIcon,
.carouselOuter[data-is-sticky=true] .carousel .carouselItem img {
  margin-top: 0; /* for .homeIcon img */
  height: 0;
  opacity: 0;
}

.carouselOuter[data-is-sticky=true] .carouselItem .label {
  position: static;
  font-size:.8125rem;
}

/* selected category - sticky state */
.carouselOuter[data-is-sticky=true] .carouselItem.selectedCategory {
  background-color: #a9d6fc; /* match image bg */
}

.selectedCategory {
  cursor: default;
}

/* WRAPPER */
.carouselWrap {
  position: relative;
  left: 0;
  margin: 0 auto;
  max-width: 1300px;
  width: 100%;
  padding: .5rem 0;
}

/* CONTROLS - DESKTOP */
.controlsWrap {
  display: none;
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  box-sizing: border-box;
}
.scrollArrow {
  cursor: pointer;
  position: absolute;
  display: none;
  opacity: 0;
  z-index: 2;
  top: 59px;
  transform: translateY(-50%);
  width: 0px;
  height: 0px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px 0px;
  background-color: #fff;
  border-radius: 50%;
  transition: all .25s ease-in-out;
}

/* CAROUSEL */
.carousel {
  height: 80px; /* 68px; */
  position: relative;
  display: flex;
  /* horizontal scroll mixin */
  composes: horizontalScroll from '../common/mixins.css';
  list-style: none;
  box-sizing: border-box;
  width: 100%;
  font: var(--zrFontBold);
  color: #767676;
  transition: height 0.33s linear;
}
.carousel::-webkit-scrollbar {
  display: none;
}

/* DD goes 96w to 74w for items in Mobile */
.carouselItem {
  flex: 0 0 64px;
  font-size: .875rem;
  padding: 0 .25rem;
  margin: 0 .125rem;
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  font-size: .875rem;
  white-space: nowrap;
  cursor: pointer;
  text-align: center;
  border-radius: .75rem;
}

.carouselItem .label {
  position: absolute;
  left: 0;
  bottom: .5rem;
  width: 100%;
  text-align: center;
  font: var(--zrFontSemi);
  font-size: .625rem;
  color: #777;
  transition: font-size 0.33s ease-out;
}

.carouselOuter[data-is-sticky=true] .carouselItem .label,
.carouselItem.selectedCategory .label {
  color: #000;
}

.carouselItem:first-of-type {
  margin-left: 0;
}

.carouselItem:last-of-type {
  margin-right: 0;
}

.carouselItem img {
  display: block;
  width: 58px; /* prev 48px */
  height: 58px;
  margin: 0 auto;
  opacity: 1;
  transition: opacity 0.33s ease-out;
}

/* Show .active only on selected */
.carouselItem .active img {
  display: none;
}
.carouselItem.selectedCategory .active img {
  display: block;
}
/* Hide default state image on selected */
.carouselItem.selectedCategory .inactive img {
  display: none;
}
/* back icon */
.carouselItem .homeIcon svg {
  padding: 10px 0;
  height: 40px; /* prev 30px */
  width: 40px;
}
.carouselItem .homeIcon img {
  margin-top: 9px;
  width: 48px; /* prev 38 px */
  height: 48px;
}

.carouselOuter[data-is-sticky=true] .carouselItem {
  flex: 1 0 76px;
}
.carouselOuter[data-is-sticky=true] .carouselItem .label {
  line-height: 36px;
  padding: 0 .125rem;
}

/* MASK FOR OVERFLOW ITEMS */
.itemMask {
  display: none;
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  width: 1.75rem;
  background-color: #fff;
}
.firstItemMask {
  left: -8px;
  -webkit-mask-image: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0px, rgb(255, 255, 255) 20px, rgb(255, 255, 255) 100%);
}

.lastItemMask {
  right: -8px;
  -webkit-mask-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0px, rgb(255, 255, 255) 20px, rgb(255, 255, 255) 100%);
}
.withLeftArrow .firstItemMask,
.withRightArrow .lastItemMask {
  display: block;
}

/* SMALL DESKTOP 601 => 1024px */
@media screen and (min-width:601px) {
  .carousel {
    height: 118px;
  }
  .carouselItem {
    flex: 0 0 120px;
    max-width: 120px;
    padding: .5rem .25rem;
    margin:0 .25rem;
  }
  .carouselItem img {
    width: 80px;
    height: 80px;
  }
  .carouselItem .label {
    bottom: .25rem;
    font-size: .875rem;
  }
  /* popular category star img */
  .carouselItem .homeIcon img {
    margin-top: 18px;
    width: 62px;
    height: auto;
  }
  /* back icon */
  .carouselItem .homeIcon svg {
    padding-top: 18px;
    height: 54px;
    width: 54px;
  }
  .controlsWrap {
    display: block;
  }

  /* controls and desktop-only hover behavior */
  .carouselOuter:hover .controlsWrap .scrollArrow {
    display: block;
    width: 36px;
    height: 36px;
  }

  .carouselItem:hover .inactive img {
    display: none;
  }
  .carouselItem.selectedCategory .active img,
  .carouselItem:hover .active img {
    display: block;
    transform: scale(1.2) rotate(1deg);
  }
  .carouselItem:hover .label {
    color: #000;
  }
  .carouselOuter[data-is-sticky=true] .carouselItem:hover {
    background-color: #a9d6fc; /* match image bg */
  }
  .itemMask {
    width: 3rem;
  }
  .firstItemMask {
    left: 0;
    -webkit-mask-image: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0px, rgb(255, 255, 255) 32px, rgb(255, 255, 255) 100%);
  }

  .lastItemMask {
    right: 0;
    -webkit-mask-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0px, rgb(255, 255, 255) 32px, rgb(255, 255, 255) 100%);
  }
  /* sticky state controls - smaller version */
  .carouselOuter[data-is-sticky=true] .controlsWrap .scrollArrow {
    top: 26px;
    height: 28px;
    width: 28px;
  }
  .carouselOuter[data-is-sticky=true] .controlsWrap .scrollArrow svg {
    transform: scale(.85);
    margin-top: -2px;
  }
  .carouselOuter[data-is-sticky=true] .controlsWrap .scrollLft svg {
    margin-left: -2px;
  }
  .carouselOuter[data-is-sticky=true] .controlsWrap .scrollRgt svg {
    margin-left: -1px;
  }
  .scrollLft {
    left: .5rem;
  }
  .scrollRgt {
    right: .5rem;
  }

  /* the inner arrow svg */
  .scrollLft svg,
  .scrollRgt svg {
    font-size: 2rem;
    margin-top: 2px;
  }
  .withLeftArrow .scrollLft,
  .withRightArrow .scrollRgt {
    opacity: 1;
    background-color: #fff;
    text-align: center;
  }
}

/* WIDESCREEN 1024+ */
@media screen and (min-width:1025px) {
  .carousel {
    height: 134px; /* This height impacts scroll calcs in the jsx */
  }
  .carouselItem {
    flex: 0 0 137px;
    max-width: 137px;
  }
  .carouselItem img {
    width: 92px;
    height: 92px;
  }
  /* popular category star image */
  .carouselItem .homeIcon img {
    margin-top: 19px;
    width: 72px;
  }
  /* back icon */
  .carouselItem .homeIcon svg {
    width: inherit; /* 50px */
    height: auto;
  }
  .scrollArrow {
    top: 75px;
    line-height: 2.25rem;
  }
  .carouselOuter[data-is-sticky=true] .scrollArrow {
    top: 30px;
  }
  .scrollLft {
    left: 0;
  }
  .scrollRgt {
    right: 0;
  }
}

/* WIDE SCREEN @ 1085: NO NEED TO MASK STICKY CATEGORY TABS */
@media screen and (min-width:1085px) {
  .carouselOuter[data-is-sticky=true] .itemMask {
    display: none;
  }
}
