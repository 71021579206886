.amount {
  color: inherit;
}

.onSale {
  text-decoration: line-through;
}

.salePrice {
  color: var(--zrTextRed);
  font-style: italic;
  font-size: 115%;
  margin: 0 0 0 .375rem;
}
