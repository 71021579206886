/* home page */
.headerWrap {
  margin-top: 2rem;
}

/* important for search */
.headerGrid {
  display: flex;
  position: relative;
}

.headerText {
  position: absolute;
  left: 0;
  line-height: 38px;
}

.promoCarouselWrapper {
  margin: 1.5rem 0 2rem;
  min-height: 150px;
}

/* Remove "Shop By Product" and keep only search input */
@media screen and (max-width:900px) {
  .headerText {
    display: none;
  }
}

.headerSearchWrapper {
  position: relative;
  font-size: 1rem;
  line-height: normal;
  margin: 0 auto;
  width: 350px;
  max-width: 95vw;
}

/* Delivery by 2:00pm */
.etaWrapper {
  position: absolute;
  right: 0;
  top: 5px;
}

.contentWrap {
  margin-bottom: 5rem;
}

@media screen and (max-width:600px) {
  .headerWrap {
    margin-top: 1rem;
  }
}
