/* adjust height for modal view */
.imageOuter.modalImage {
  height: 260px;
  position: relative;  
}

/* for positioning images */
.imageWrap {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: .25rem;
}

.imageOuter:not(.modalImage) .imageWrap {
  top: .75rem;
  right: .75rem;
  width: calc(100% - 1.5rem);
  height: calc(100% - 1.5rem);
  border-radius: .25rem;
}

.productImage {
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 100%;
  max-width: 100%; /* compromise to better handle lansdscape images (but you can lose rounded corners) */
  transform: translate(-50%, -50%);
  border-radius: .25rem;
}

/* handle portrait image in modal */
.blurredImage {
  width: 100%;
  height: 100%;
  filter:blur(10px);
}

/* this was for full bleed */
.imageOuter:not(.modalImage) .blurredImage {
  display: none;
}

.imageOuter.modalImage.stockImage .imageWrap .productImage {
  max-height: 120%; /* stock images have padding, so dial up height */ 
}

/* image coming soon overlay */
.placeholderMessage {
  position: absolute;
  top: 75%;
  left: .75rem;
  right: .75rem;
  transform: translateY(-50%);
  border-radius: 24px;
  font: var(--zrFontSemi);
  font-size: .5625rem;
  line-height: 1.375rem;
  overflow: hidden;
}

.modalImage .placeholderMessage {
  left: 8%;
  right: 8%;
  font-size: 1rem;
  line-height: 2.25rem;
}

/* MUI md breakpoint */
@media screen and (max-width:960px) {
  .imageOuter.modalImage {
    height: 250px;
  }
}

/* tablet product page */
@media screen and (max-width:800px) {
  .placeholderMessage {
    font-size: .5rem;
    left: 0;
    right: 0;
  }
  .modalImage .placeholderMessage {
    line-height: 2.375rem;
  }  
}

/* adjust modal for mobile */
@media screen and (max-width:480px) {
  .imageOuter.modalImage {
    height: 200px;
  }
  .modalImage .placeholderMessage {
    font-size: 1.25rem;
  }
}
