/**
 * For the product card itself, don't set min/max heights,
 * allow the flex carousel to figure it as out as card content varies
 */
.productCardWrap {
  min-height: 200px; /* This is just for loading to reduce page jump */
  position: relative; /* For the Sold Out layer */
}

.productCard {
  position: relative;  /* Added check */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

/* card elements */
.productImageWrap {
  position: relative;
}

.productImage {
  display: block;
  margin:.5rem auto;
  border-radius: .25rem;
  max-width: calc(100% - 1rem);
  height: 175px;
}

/**
 * .spinnerWrap relates to the deferred product image load for offscreen carousel items
 * and needs to replicate the PreviewProductImage.module.css product image heights
 */
.spinnerWrap {
  padding-top: 2rem;
  box-sizing: border-box;
  height: 150px;
}
@media screen and (max-width:600px) {
  .spinnerWrap {
    height: 140px;
  }
}

.productBody {
  position: relative;
  padding: .375rem .75rem 10px;
}

.productName {
  color: var(--zrBlack);
  font: var(--zrFontBold);
  text-decoration: none;
  margin-bottom: .6125rem;
  composes: twoLines from '../common/mixins.css';
}

.productInfo {
  margin: .6125rem 0 .675rem;
}

/* No excessive margin! we use line-height here */
.brand {
  color: #000;
  font-size: .75rem;
  line-height: 20px;
  margin-top: -2px;
  max-width: 220px;
  composes: ellipses from '../common/mixins.css';
}

.productDesc {
  margin: 0 0 1.5rem;
}

.productPrice {
  margin-bottom: .675rem;
  font: var(--zrFontSemi);
  font-size: .875rem;
  color: #000;
}

.discountIcon {
  vertical-align: -3px;
  margin-left: 4px;
  margin-right: 4px;
}

.orderQuantity {
  color: var(--zrPrimaryDark);
  font: var(--zrFontBold);
  margin: 0 .5rem;
}

.addedWrapper {
  font-weight: normal;
  position: absolute;
  z-index: 0; /* so as not to overlap sticky category filter */
  top: .375rem;
  right: .375rem;
}

/* align details to bottom of card */
.dealDetailsWrapper {
  display: flex;
  flex-direction: column;
  padding: 0 12px 12px;
}

.saleLabel {
  text-align: center;
  font: var(--zrFontSemiItalic);
  color: #fff;
  background-color: #C83C32; /* 5:1 contrast */
  padding: 4px 0 5px;
  border-radius: 0 0 8px 8px;
}

.saleLabel.qtyLimit {
  background-color: #000;
}

.saleLabel.superDeal,
.saleLabel.superDealWithLimit {
  background-color: #907725;
}

/* multiple contexts */
.qtyLimitPill {
  display: inline-block;
  vertical-align: 1px;
  font-size: 13px;
  line-height: 100%;
  padding: 1px 8px 3px;
  margin: 0 0 0 10px;
  border-radius: 4px;
  color: #fff;
  background-color: #000;
}

/* bundle pricing */
.saleLabelWithTooltip {
  margin-top: -10px;
}

@media screen and (max-width:600px) {
  .productBody {
    padding: .375rem .75rem 8px;
  }
  .productName {
    font-size: 1rem;
  }
  .saleLabel {
    margin: 0 12px;
    padding: 3px 0 4px;
    font-size: 14px;
    line-height: 129%;
  }
  .saleLabel.qtyLimit {
    font-size: 13px;
    line-height: 119%;
  }
  .saleLabel.superDeal,
  .saleLabel.superDealWithLimit,
  .saleLabel.qtyDiscount {
    font-size: 12px;
  }
  /* targets only carousel card */
  .saleLabel .qtyLimitPill {
    vertical-align: baseline;
    font-size: 12px;
    padding: 0 4px 1.5px;
    margin: 0 0 0 5px;
  }
  /* modal suggested carousel */
  .saleLabel[data-is-suggested="true"] {
    margin: 0;
  }
}

@media screen and (max-width:480px) {
  .productName {
    margin-bottom: .375rem;
  }
  .productInfo {
    margin: .25rem 0 .5rem;
  }
}
