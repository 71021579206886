.cartIconWrapper {
  padding: 4px 18px 4px 8px;
  margin: 0 auto;
  position: relative;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  background-color: #bccad2;
  border-radius: 6px;
  color: #fff;
}

.cartIconWrapper:hover {
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
}

.cartIconWrapper svg {
  vertical-align: middle;
}

.itemCount {
  vertical-align: -3px;
  text-align: right;
  font: var(--zrFontBold);
  font-size: 1.125rem;
  margin-left: 0.5rem;
}

.itemCountFooter {
  position: absolute;
  top: -.25rem;
  right: -.25rem;
  font: var(--zrFontBold);
  font-size: .8125rem;
  line-height: 17px;
  text-align: center;
  color: var(--zrPrimaryVeryDark);
  background-color: var(--zrOrange);
  border: 1px solid var(--zrOrange);
  border-radius: 50%;
  content: " ";
  width: 18px;
  height: 18px;
}
