/* ReOrderPage */
.reorderPage {
  width: 706px;
  max-width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: .5rem;
}

.pageHeaderWrap {
  padding: .75rem 1rem .5rem;
}

.pageHeader {
  background-color: var(--zrPrimaryBackground);
  color: #000;
  overflow: hidden;
  border-radius: .5rem;
  padding: 1.5rem 2rem 1.25rem;
}

.headerNote {
  margin-top: 1.5rem;    
}

.backToOrders {
  margin: 16px 12px 0 !important;
}
/* End ReOrderPage */

/* ReOrderDetails */
.reorderWrap {
  padding: 1rem 8rem 3rem;
}

.reorderItems {
  margin: 1rem 0 2rem;    
}

.btnWrap {
  width: 95%;
  margin: 12px auto 24px;
}
/* End ReOrderDetails */

/* ReOrderItem */
.reorderItem {
  position: relative;
  margin: 0 0 1rem;
  padding: .5rem .25rem .5rem 0;
  border-bottom: 1px solid #e6e7e8;
}

.itemDetails {
  display: flex;
  font: var(--zrFontSemi);
}

.itemCount {
  min-width: 30px;    
}

.itemTitle {
  flex: 10;
  padding-right: .5rem;    
}

.itemPriceCol {
  flex:1;    
}

.itemPrice {
  text-align:right;    
}

.imageWrap {
  position: relative;
}

.itemImg {
  display: block;  
  max-width: 100%;
  height: 120px;
  border-radius: .5rem;
  margin: .5rem auto 0;    
}

.imageWrap:hover .itemControl {
  opacity: 1;  
}

.itemControl {
  display: inline-block;
  position: absolute;
  cursor: pointer;
  color: #fff;
  background: var(--zrPrimaryDark);
  opacity: .6;
  box-sizing: border-box;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  text-align: center;
  font-family: 'var(--zrFontBold)';
  line-height: 23px;
  font-size: 26px;
  margin: 0 3px 0 0;
  transform: translateY(-50%);   
}

.increase { top: 50%; right: 3rem;}
.decrease { top: 50%; left: 3rem;}

.btmRow {
  width: 95%;
  margin: 0 auto;
  padding: 12px 0 6px;
}

.inventoryAlert {
  box-sizing: border-box;
}

.cartButton {
  width: 100%;
  margin: 0;
}
/* End ReOrderItem */

@media screen and ( max-width: 800px) {
  .reorderWrap {
    padding: 1rem 3rem 5rem;
  }    
}

@media screen and ( max-width: 480px) {
  .reorderPage {
    margin-top: -2rem;
    margin-bottom: -4rem;    
  }
  .reorderWrap {
    padding: 1rem .75rem 5rem;
  }
  .pageHeader {
    padding: 1.5rem 1rem 1.25rem;
  }
  .headerNote {
    margin-top: 1rem;    
  }
  .reorderItem .itemImg {
    height: 90px;  
  }  
}
