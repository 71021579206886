/* EnterDiscountCode */
.wrapper {
  display: block;
}

.codeForm {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: .5rem;
}

.label {
  flex: 0;
  white-space: nowrap;
  text-align: right;
}

.input {
  flex: 1;
  margin: 0 .5rem 0 0;
  box-sizing: border-box;
}

.input input {
  font-weight: 600;
  width: 100%;
  box-sizing: border-box;
  padding: 7px 8px;
  font-size: 1.125rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #62b0f411;
}

.button {
  flex: 0;
}

/* Styling should match the related .applyCreditButton */
.applyCodeButton {
  padding: 6px 8px;
  min-width: 121px;
  min-height: 37px;
}

.removeDiscount {
  cursor: pointer;
  color: #850000;
  margin: 0 12px;
  font-size: .875rem;
  line-height: 100%;
  padding: 2px 8px 4px;
  white-space: nowrap;
}

.removeDiscount:hover {
  text-decoration: underline;
}

.alert {
  padding: 6px 12px 2px !important;
  margin: 1rem 0 .5rem;
  border-radius: 4px !important;
}

@media screen and (max-width: 600px) {
  .input {
    flex-basis: 50%;
  }
  .label {
    margin-bottom: 1rem !important;
  }
  .removeDiscount:hover {
    text-decoration: none;
  }
}
