
/* DisplayCreditAtCheckout */
.credit {
  position: relative;
  display: flex;
  align-items: center;
  padding: .75rem .25rem .75rem .5rem;
  background-color: #e8f4fd;
  border-radius: 4px;
  font: var(--zrFontSemi);
}

.type {
  flex: 0;
  padding-right: 8px;
  white-space: nowrap;
  color: var(--zrPrimaryDark);
}

.user {
  flex: 1;
  font-weight: 400;
  line-height: 133%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
}

.amount {
  flex: 0;
  padding-left: 6px;
  color: var(--zrPrimaryDark);
  font-size: 1rem;
}

.remove {
  cursor: pointer;
  color: var(--zrPrimaryVeryDark);
  line-height: 100%;
  padding: 0 0 0 8px;
  white-space: nowrap;
  font-size: 14px;
  margin-top: -8px;
}

.remove:hover {
  text-decoration: underline;
}

.removeIcon {
  vertical-align: -5px;
  font-size: 20px;
  color: var(--zrPrimaryVeryDark);
  margin: 0 2px;
};

.warnings {
  margin: 1rem 0 0;
}

.alert {
  border-radius: 4px;
  padding: 6px 12px 2px;
}

.applyCreditWrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.applyCreditButton {
  padding: 6px 8px;
  min-height: 37px;
  min-width: 121px;
  margin-bottom: 0;
}

.creditAmount {
  white-space: nowrap;
  font-weight: 600;
  color: var(--zrPrimaryDark);
  margin: 0 .25em;
}

@media screen and (max-width: 600px) {
  .credit {
    font-size: 14px;
  }
  .remove:hover {
    text-decoration: none;
  }
}
