.pageFooter {
  z-index: 1;
  margin-bottom: 3.75rem; /* FixedFooterNav */
  box-sizing: border-box;
  width: 100%;
  height: 8rem;
  padding: 2rem;
  background-color: var(--zrFooterColor);
  color: #fff;
  text-align: center;
  line-height: 140%;
}

.pageFooter .zrLogo {
  height: 1.75rem;
  vertical-align: middle;
  margin-right: 1rem;
}

.pageFooter .copyright,
.pageFooter .contentLink {
  font: var(--zrFontSemi);
  font-size: .75rem;
  color: #fff; 
  vertical-align: middle;
  margin-right: 1rem;
}

.pageFooter .contentLinks {
  margin:0;
}

.pageFooter .contentLinks .contentLink {
  cursor: pointer;    
  text-decoration: none;
}

.pageFooter .contentLinks .contentLink:hover {
  text-decoration: underline;
}

.socialIcons {
  white-space: nowrap;
}

.socialIcons svg {
  vertical-align: middle;
}

.socialIcons .socialLink {
  vertical-align: middle;
  margin: 0 .25rem;
  color: #fff;    
}

@media screen and (max-width:480px) {
  .pageFooter {
    padding: 1.25rem 1rem 2rem;    
  }
  .pageFooter .contentLinks {
    display: block;
  }
}
