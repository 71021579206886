.placeOrderWrapper {
  max-width: 800px;
  margin: 0 auto;
}

.header {
  color: #000;
}

.section {
  display: flex;
  padding: 1rem;
  border-bottom: 1px solid #e6e7e8;
}

.section:first-of-type {
  padding-top: 0;
}

.section:last-of-type {
  border:none;
}

.sectionTitle {
  flex: 0 1 160px;
  font: var(--zrFontSemi);
  margin: 0 0 1rem;
}

.sectionInner {
  flex: 1;
  font: var(--zrFontSemi);
  color: #555;
}

.sectionInner label,
.sectionInner input[type="radio"] {
  margin: 0 .5rem 0 0;
}

.disabled {
  color: #c0c0c0;
}

@media screen and (max-width:800px) {
  .section {
    display: block;
  }
}

/* PlaceOrderItem */

.cartItem {
  position: relative;
  display: flex;
  flex-wrap: wrap; /* for warnings displayed below */
  justify-content: left;
  margin: 0 0 1.75rem;
  line-height: 24px;
}

@media screen and (min-width:600px) {
  .cartItem {
    margin: 0 0 2.5rem;
  }
}

.cartItem:last-of-type {
  margin: 0 0 .75rem;
}

.qtyLimit {
  color: #1879c3;
  position: absolute;
  right: 0px;
  top: -1px;
  font-size: 13px;
  letter-spacing: .5px;
}

.itemTitle {
  flex: 10;
  padding-right: 6px;
}

.itemCount,
.itemPrice {
  flex: 1;
}

.itemCount {
  padding-right: 3px;
}

.itemPrice {
  position: relative;
  text-align: right;
}

.controls {
  flex: 0 0 24px;
  margin-right: .675rem;
  position: relative;
}

.control img {
  position: absolute;
  left: 0;
  height: 22px;
}

.control.increase img {
  top:-9px
}

.control.decrease img  {
  top: 18px;
}

.control.disabledControl {
  opacity: .2;
}

.removeFromOrder {
  cursor: pointer;
  color: var(--zrPrimaryDark);
  font: var(--zrFontSemi);
  font-size: .675rem;
  margin-left: .5rem;
  vertical-align: 4px;
}

