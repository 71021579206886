.fixedFooterNav {
  height: 3.75rem;
  background-color: #fff;
  position: fixed;
  display: flex;
  justify-content: space-around;
  align-items: center;
  bottom: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid var(--zrLightBorder);
  /* box-shadow: 0 -1px 0 rgba(0,0,0,.1); */     
}

.navItem {
  /* default - flex: 0; */
  cursor: pointer;
  position: relative;
  z-index: 1;
  min-width: 50px; /* ensure middle icon is centered */
}

.navItem svg {
  display: block;
  margin: 0 auto;
}

/* careful, spills into cart without > */
.navItem:hover > svg {
  color: var(--zrPrimaryDark) !important;
}

.navLabel {
  font: var(--zrFont);
  font-size: .75rem;
  text-align: center;
}

.navItem.bagIcon {
  z-index: 0;  /* otherwise open cart renders other footer icons unclickable */
}

.navItem.referIcon svg {
 padding: 1px 0;
}

.navItem.ordersIcon {
  z-index: 2; /* for progress pilll */
}

@media screen and (max-width:600px) {
  /* mobile has its own placement */
  .navItem.bagIcon {
    display: none;
  }  
}

.navItem.bagIcon svg {
  display: block;
  margin: -1px auto 1px;
}

.navItem .creditCount,
.navItem .unReadCount {
  position: absolute;
  top: -5px;
  right: -4px;
  background-color: var(--zrOrange);
  border: 1px solid var(--zrOrange);
  color: var(--zrPrimaryVeryDark);
  border-radius: 50%;
  width: 18px;
  height: 18px;
  font: var(--zrFontBold);
  font-size: .8125rem;
  line-height: 16px;
  text-align: center;
}

/** 
 * Hack: The Footer is outside routing so we're using an attribute assigned by navigateTop 
 * to disable the same page icon ( home on home, etc. )  
 */
body[data-route="referralCode"] .referIcon svg g { fill: var(--zrPrimaryDark) !important;}
body[data-route="refer"] .referIcon svg g { fill: var(--zrPrimaryDark) !important;} 
body[data-route="account"] .avatarIcon svg path:nth-of-type(2),
body[data-route="account"] .avatarIcon svg path:nth-of-type(3),
body[data-route="account"] .avatarIcon svg path:nth-of-type(5) {
  fill: var(--zrPrimaryDark) !important; 
}

body[data-route="home"] .homeIcon svg path:nth-of-type(2),
body[data-route="orders"] .ordersIcon svg path:nth-of-type(2) {
  fill: var(--zrPrimaryDark) !important; 
}

body[data-session="user"] .referIcon { display:block;}
