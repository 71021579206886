.productCardWrap {
  position: relative;
  background-color: #fff;
  border-top: 1px solid #d7e4eb;
  cursor: pointer;
}

.productCardWrap:last-of-type {
  border-bottom: 1px solid #d7e4eb;
}

.productCard {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  box-sizing: border-box;
  min-height: 150px;
}

.productImageWrap {
  position: relative;
  flex: 0 0 150px;
  min-height: 150px;
}

.productBody {
  flex: 1;
  padding: 0 0 0 .75rem;
}

.brand {
  color: #000;
  font-size: .875rem;
  composes: ellipses from '../common/mixins.css';
}

.productName {
  color: var(--zrBlack);
  font: var(--zrFontBold);
  font-size: 1.125rem;
  margin:.375rem 0 .5rem;
  text-decoration: none;
  composes: twoLines from '../common/mixins.css';
}

.productInfo {
  margin: .5rem 0 0;
}

.productPriceWrap {
  position: relative;
}

.productPrice {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  min-width: 120px;
  padding: 0 1.25rem 0 1rem;
  margin-right: .75rem;
  font: var(--zrFontBold);
  font-size: .875rem;
  line-height: 3rem;
  text-align: center;
  background-color: #eaeff2;
  border-radius: .375rem;
  color: #000; /* #9b9b9b fails contrast */
}

.productWeight {
  font-size: .75rem;
  font-weight: normal;
}

.productWeightDash {
  font-size: .75rem;
  font-weight: normal;
  margin: 0 .25rem
}

.discountIcon {
  margin: -1px 5px 0;
}

.limitChip {
  cursor: pointer;
  position: absolute;
  z-index: 1;
  bottom: 4px;
  left: 0;
  right: 0;
  font-weight: 600;
  color: #fff;
  background-color: #000;
}

.orderQuantity {
  position: absolute;
  top: -.75rem;
  right: 0;
  font: var(--zrFontSemi);
  border-radius: 1rem;
  background-color: #fff;
  width: 1.675rem;
  line-height: 1.675rem;
  text-align: center;
  box-shadow: 0 0 3px #000;
}

/* Add to cart */
.mobileControls {
  display: none;
}

.dealDetails {
  margin-top: 6px;
}

.addedWrapper {
  font-weight: normal;
  position: absolute;
  z-index: 0; /* so as not to overlap sticky category filter */
  top: -18px;
  right: 0;
}

@media screen and (max-width:800px) {
  .productBody {
    order: 2;
  }
  .productImageWrap {
    order: 1;
    flex: 0 0 140px;
    min-height: 140px;
  }
  .desktopControls {
    order: 3;
  }
  .quickAddWrap {
    display: inline-block;
    position: relative;
  }

  .productPrice {
    display: inline-flex;
    min-width: 148px; /* space for quick add */
    line-height: 2rem;
  }
  .productCardWrap {
    padding: .5rem 0;
  }
}

@media screen and (max-width:600px) {
  .productCard {
    align-items: flex-start;
    min-height: auto;
  }
  .productBody {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0 0 .5rem;
  }
  .productName {
    order: 1;
    font-size: 1rem;
    margin: 0 0 .25rem;
  }
  .brand {
    order: 2;
    font-size: .8125rem;
  }
  .productInfo {
    order: 3;
  }
  .addedWrapper {
    top: -24px;
  }
  /* Add to cart */
  .mobileControls {
    order: 3;
    display: block;
    margin-top: 2rem;
  }
  .desktopControls {
    display: none;
  }
}

@media screen and (max-width:480px) {
  .quickAddWrap {
    display: block;
    padding-right: .5rem;
    margin-right: 0;
    box-sizing: border-box;
  }
  .productPrice {
    display: flex;
    padding: 0 .25rem;
    margin-right: .5rem;
  }
}
