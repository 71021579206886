/* center everything */
.weight,
.icon,
.gramCount,
.gramLabel {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.weight,
.icon {
  height: 40px;      
}

.gramCount{
  top: 21px;
  font: var(--zrFontSemi);
  font-size: .75rem;  
}

.gramLabel {
  top: 32px;  
  font-size: .625rem;
  font-weight: normal;    
}
