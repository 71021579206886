.signInWrap {
  max-width: 400px;
  margin: 2rem auto;
}

.yourAccountWrap {
  padding-top: 1.5rem;
  max-width: 928px;
  margin: 0 auto;
}

.formWrap {
  border: 1px solid #ccc;
  border-radius: .5rem;
  margin: 1rem auto 2rem;
}

/* the Account page header */
.headerWrap {
  display: flex;
  justify-content: space-between;
  padding: .75rem 1rem;
  background-color: rgba(0,0,0,.05);
  border-radius: .5rem .5rem 0 0;
}

.headerItem {
  flex: 1;
  padding-right: .5rem;
}
.headerItem:last-of-type {
  flex: 0;
  padding-right: 0
}

.headerItem .accountVal {
  font-size: 1.25rem;
  white-space: nowrap;
}

/* EditableFullName component */
.editableName {
  position: relative;
  padding-right: 48px;
}

.editableNameLink {
  cursor: pointer;
  position: absolute;
  font-size: 13px;
  margin-left: 4px;
  top: -8px;
  color: #595959;
  text-decoration: underline;
}

.editNameInput {
  margin-bottom: 12px !important;
}

.editNameInput input {
  min-width: 270px;
  color: var(--zrPrimaryDark);
  font-size: 1.125rem;
  background-color: #fff;
  padding-left: 4px;
  padding-right: 4px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.editNameButtons {
  display: flex;
  justify-content: space-between;
}

.editNameButton {
  padding: 6px 8px;
  min-width: 48%;
  margin-bottom: 6px;
}

.editNameCancelButton {
  background-color: #ccc;
}

.editNameResponse {
  text-align: left;
}

@media screen and (max-width:800px) {
  .editNameResponse {
    text-align: right;
  }
}

.accountSection {
  align-items: center;
  padding: .5rem 1rem 1.5rem;
  border-bottom:1px solid #ccc;
}

.accountSection:last-of-type {
  border: none;
}

.accountSection :global .MuiFormControl-root .MuiFormHelperText-root.Mui-error {
  color: #000;
}

.accountPassword {
  display: flex;
  padding-bottom: 1.5rem;
}

.accountPassword .accountLbl {
  min-width: 120px;
}

.accountSection .accountLbl {
  margin: 0 1rem 0 0;
}

.accountSection.withForm {
  display: flex;
  align-items: flex-start;
  margin: 1.5rem 0;
}

.accountSection.withForm .sectionForm {
  flex: 1;
  max-width: 622px;
}

.accountSection.withForm .accountLbl {
  flex: 0;
  min-width: 120px;
}

.accountLbl {
  font: var(--zrFontSemi);
  font-size: 15px;
  text-transform: uppercase;
  margin: 0 0 .5rem;
  white-space: nowrap;
  color: #595959;
}

.sideBySide {
  display: flex;
  justify-content: space-between;
}

.sideBySide .imageCol {
  flex: 1;
}

/* faded during update request */
.faded {
  opacity: .5;
}

/* Your account styles */
.updateBtnWrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;  
}
.updateBtnWrap .formButton {
  flex: 1 1 50%;
}
.updateBtnWrap .updateMsg {
  flex: 1 1 50%;
  margin-left: 1rem;
}

/* gray background for inputs /  white on hover */
.sectionForm :global .MuiFilledInput-root {
  background-color: rgba(0,0,0,.05);
}

.sectionForm :global .MuiFilledInput-root.Mui-focused,
.sectionForm :global .MuiFilledInput-root:hover,
.sectionForm :global .MuiFilledInput-root .MuiFilledInput-input {
  background-color: transparent;
}

/* update phone number */
/** PhoneInput styles for the 'react-phone-number-input' component **/

.sectionForm  :global .PhoneInput {
  position: relative;
}

/* country select */
.sectionForm :global .PhoneInputCountrySelect {
  position: absolute;
  z-index: 2;
  top: 11px;
  right: 10px;
  width: 65px;
  padding: 10px;
  opacity: 0;
  cursor: pointer;
}

.sectionForm :global .PhoneInputCountrySelectArrow {
  top: 14px;
  right: 15px;
  position: absolute;
  height: 20px;
  width: 20px;
}

.sectionForm :global .PhoneInputCountrySelectArrow:before {
  position: absolute;
  top: 0;
  right: 0;
  content: '\25B2';
  font-size: 14px;
  color: #999;
}

.sectionForm :global .PhoneInputCountrySelectArrow:after {
  position: absolute;
  top: 14px;
  right: 0;
  content: '\25BC';
  font-size: 14px;
  color: #999;
}

.sectionForm :global .PhoneInputCountryIconImg {
  padding: 8px 23px 8px 8px;
  right: 10px;
  position: absolute;
  height: 20px;
  top: 11px;
  border: 1px solid #ccc;
}

/* phone input  - style like the Material UI inputs */
.phoneInputWrapper {
  position: relative;
  margin: 0 0 1rem;
}

.sectionForm :global .PhoneInputInput {
  font-size: 1.2rem;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  background-color: var(--zrLightInputBackground);
  width: 100%;
  padding: 19px 1rem 16px;
  border-width: 0 0 1px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  box-sizing: border-box;
  margin-bottom: 1px;
}
  
.phoneInputError :global .PhoneInputInput {
  color: #f44336;
  border-bottom: 2px solid #f44336;  
}

.phoneInputError :global .PhoneInputInput:focus {
  color: #000;
  border-bottom: 2px solid #f44336;
}

.sectionForm :global .PhoneInputInput:focus {
  background-color: #fff;
  border-bottom: 2px solid var(--zrPrimaryDark);
  outline: none;
  margin-bottom: 0;
}

.phoneNumberErrorMsg {
  font-size: .875rem;
  text-align: left;
  margin: 0;
}

.phoneNumberErrorMsg :global .MuiSvgIcon-root {
  vertical-align: middle;
  margin-right: .5rem;
}

/* Update Photo */
.idImageWrap {
  padding: 0 1rem 1rem;    
}

.idImage {
  display: block;
  margin: 0 auto;
  width: 450px;
  max-width: 100%;
  border-radius: 1rem;
}

.idButtonWrap button {
  display: block;
  margin-left: auto;
  margin-right: auto;    
}

.withBtmMargin {
  margin:0 0 1rem;
}

/* Email subscribe */
.subscribe {
  margin-top: -10px;
}

.subscribeItem {
  cursor: pointer;
  margin: 0 0 1rem;
}
/* delivery sms status */
.readOnly {
  border-color: #ccc;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  padding: 7px 12px 12px;
  margin-top: 1rem;
}
.readOnly legend {
  font: var(--zrFontSemi);
  letter-spacing: .25px;
  font-size: .875rem;
  color: #595959;
  padding-bottom: 4px;
}
.alert {
  margin: 0 0 12px !important;
}
.statusNote {
  font-size: .75rem;
  padding: 0 1rem;
}

.sectionForm :global .MuiFormControlLabel-root {
  align-items: flex-start;
}
.sectionForm :global .MuiTypography-body1 {
  padding-top: .5rem;
}

.sectionForm :global .MuiFilledInput-root {
  background-color: var(--zrLightInputBackground);
}  

.notificationList {
  margin-top: 3px;
}

.notification {
  display: flex;
  padding: .5rem 1rem .5rem 0;
  border-bottom: 1px solid #ccc;
}

.notification:first-of-type {
  border-top: 1px solid #ccc;
}

.notification.unViewed {
  background-color: #ffb50088;
  transition: background-color 1s ease-in-out;
}

.notificationList[data-viewed="true"] .notification.unViewed {
  background-color: #fff;
}

.ntfyIcon {
  flex: 0;
  padding-left: 4px;
  min-width: 24px;
  text-align: center;
}

.ntfyText {
  flex: 1;
  padding: 2px 4px;
  font: var(--zrFontSemi);
}

.ntfyText .ntfyDetails {
  font-size: .875rem;
  color: #595959;
  margin-top: 2px;
}

.ntfyDate {
  flex: 0;
  white-space: nowrap;
  font: var(--zrFont);
  font-size: .875rem;
  color: #595959;
}

/* Account Header goes non-grid at 800px */
@media screen and ( max-width:800px ) {
  .headerWrap {
    display: block;
  }

  .headerWrap .headerItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    padding-right: 0;
  }

  .headerWrap .headerItem.headerItemName {
    align-items: flex-start;
  }  

  .headerWrap .headerItem .accountLbl {
    margin-right: .5rem;
    margin-bottom: 0;
  }

  .headerWrap .accountVal {
    font-size: 1rem;
  }
}

@media screen and ( max-width:600px ) {
  .accountSection.withForm {
    display: block;
    margin: 1rem 0;
  }

  .sideBySide {
    display: block;
  }

  .accountSection.withForm .accountLbl {
    margin-bottom: 1rem;  
  }
    
  .idImageWrap:first-of-type {
    padding-top: 0;
  }

  .idImageWrap {
    padding: 1rem;    
  }
  
  .idButtonWrap {
    margin-bottom: 1rem;
  }

  /** these styles used in address form */
  .updateBtnWrap {
    display: block;
  }
  
  .updateBtnWrap .updateMsg {
    display: block;
    margin: 1rem 0;
    text-align: left;
  }
  .subscribe {
    margin-top: 0;
  }
}

.stronger {
  font-weight: 600;
}

/* AerosyncFlowMessaging */
.aerosyncMsg {
  margin: 1rem 0;
}

.spinnerMsg {
  font: var(--zrFontSemi);
  color: var(--zrPrimaryDark);
}

.withoutForm {
  margin-top: -3px;
}

@media screen and (max-width: 600px) {
  .withoutForm {
    margin-top: 0;
  }
}

.smsNumber {
  font: var(--zrFontSemi);
  text-decoration: none;
  color: var(--zrPrimaryDark);
}

/* Email / SMS SubscribeForm toggles */
:global .MuiSwitch-colorSecondary + .MuiSwitch-track {
  background-color: var(--disabledGray);
}
:global .MuiSwitch-colorSecondary {
  color: var(--disabledGray);
}  

:global .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: var(--zrPrimaryDark);
}

:global .MuiSwitch-colorSecondary.Mui-checked {
  color: var(--zrPrimaryDark);
}
