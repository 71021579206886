/**
 * Prevent body content from getting too wide 
 *
 * Be careful not to set min-heights on the layout as this
 * can cause crazy behavior with the iframe re-sizing code
 * ( the nav and footer prevent the payout content from ever being 100vh )
 */

.defaultWrapper {
  max-width: 1300px;
  margin: 0 auto 2rem;
  padding: 0 2rem;
  min-height: calc(100vh - 15rem);
  /**
   * padding-bottom: -- You shouldn't add bottom padding due to same-page re-use of wrappers
   * and also dispensary page layout with cart on page --- padding creates an ugly gap 
   */ 
}

.narrowWrapper {
  max-width: 1100px;
}

.homeGridWrapper {
  max-width: 1160px;
  margin: 3rem auto 0; 
  overflow: hidden;
} 

.fullBleedWrapper {
  max-width: none;
  padding: 0;       /* add padding if needed to child components, see /dispensary */
  margin-bottom: 0; /* order confirmation map fix */
  min-height: auto;
}

@media (max-width:480px) {
  .defaultWrapper {
    margin: 0 1rem;
    padding: 0;
  }
  .fullBleedWrapper {
    margin-left: 0;
    margin-right: 0;
  }
} 
