
.cobrandGrid {
  margin: 1rem 0 0;  
  display: flex;
  column-gap: 1rem;
}

.cobrandItem {
  font-size: 1.125rem;  
  flex: 0 0 calc(50% - 8px);    
}

/* remove grid here, long brand names break grid */
@media screen and (max-width: 960px) {
  .cobrandGrid {
    display: block;
  }  
}
