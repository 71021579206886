.strainWrap {
  font: var(--zrFontSemi);
  font-size: 1.5rem;
  display: flex;
  align-items: center;
}

.strainIcon {
  margin: 0 .25rem;
  height: 2rem;
}

.compounds {
  margin: 1rem 0 0;
  display: flex;
  flex-wrap: wrap;
}

.compound {
  font: var(--zrFontBold);
  font-size: 1.5rem;
  margin-right: 1rem;
  margin-bottom: .5rem;
}

.compoundLabel {
  font: var(--zrFontSemi);
  font-size: .875rem;
}

.totalTerps {
  margin: 0 0 4px;
}

.totalTerpsValue {
  font-weight: 600;
  margin: 0 .25em;
}

@media screen and ( max-width:600px ) {
  .strainWrap {
    font-size: 1.25rem;
  }
  .compound {
    font-size: 1.25rem;
  }
  .disclaimor {
    font-size: .875rem;
  }
}
