.mapWrapper  {
  margin: 2rem 0;
  border-radius: .5rem;
  overflow: hidden;
}

.mapWrapper :global #map {
  margin: 0 !important;
}

.weeklyHours li {
  display: flex;
  white-space: nowrap;
  margin: 0 0 .5rem;    
}

.weeklyHours .bizDay {
  flex: 1;  
  font: var(--zrFontSemi);
}

.weeklyHours .bizHours {
  flex: 0;
}

/* sidebar */

.sideBarCard {
  margin: 2rem 0 0;
  background-color: var(--menuGray);
  border-radius: .75rem;
  padding: 1.5rem 2rem;
  box-sizing: border-box;
  width: 100%;
}

.infoHdr {
  margin: 1rem 0 12px !important;
}

.infoItem {
  margin-top: 1.5rem;
  font-size: .875rem;
}

.gridInfoHdr {
  font: var(---zrFontSemi);
  margin-bottom: .25rem;
}

.gridText {
  padding-left: .5rem;
}

.pill {
  vertical-align: 2px;
  font-size: .75rem;
  border-radius: 18px;
  color: #000;
  background-color: var(--zrPrimary);
  padding: .25rem .75rem;
  margin: 0 .75rem;  
}

.contactItem {
  margin: 0 0 12px;
}

.contactLbl {
  font:  var(--zrFontSemi);
  margin-right: 8px;
}
.contactLnk {
  color: var(--zrPrimaryDark);
  text-decoration: none;
}

/* MUI Grid Small breakpoint */
@media screen and ( max-width:600px ) {
  .sideBarCard {
    width: calc(95vw - .75rem);
  }
  .mapWrapper  {
    margin: 1rem 0  2rem;
  }  
}
