.pageHeader {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  color: #000;
  font:var(--zrFont);
  font-size: .875rem;
  background-color: #fff;
  box-sizing: border-box;
  left: 0;
  right: 0;
  height: 3rem;
  /* dutchie */
  border-bottom: 2px solid var(--zrLightBorder);  /* outer 1px is #d7e4eb */
  box-shadow: rgb(0 0 0 / 4%) 0px 3px 1px;
}

/* left side */
.navLinks {
  flex: 1;
  height: 40px;
}

.navLink {
  cursor: pointer;
  margin: 0 .5rem;
}

.navLink:hover {
  text-decoration: underline;
}

.burgerWrap,
.cartWrap {
  flex: 0;
}

:global .hamburger-react {
  left: 1px;
  top: 1px;
  padding-right: 4px;
}

/* The lines */
:global .hamburger-react div div {
  height: 2.5px !important;
}

/* stacking! */
.burgerIcon {
  position: relative;
}
/* TODO FIXME: burger icon bleed-thru when cart overlaps */
@media screen and (max-width:480px) {
  body[zr-cart-open="true"] .burgerIcon {
    z-index: 1 !important;
  }
}

.cartWrap {
  display: flex;
  flex: 0;
  margin-right: .75rem;
  align-items: center;
}

.centerArea {
  flex: 1;
  position: absolute;
  width: 100%;
}

/* centered logo container */
.logoWrap {
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -45%);
}

.zrLogo {
  cursor: pointer;
  display: block;
  height: 36px;
  margin-top: -2px;
}

.infoWrap {
  cursor: pointer;
  margin: 0 .5rem;
}

@media screen and (max-width:600px) {
  .pageHeader {
    justify-content: space-between;
  }
  .navLink {
    display: none;
  }
  .cartWrap {
    margin-right: 7px;
  }
}

/* Deliver To: inline + bar */

/* HACK - Sign up has an address typeahead instance, hide deliverTo */
body[data-route="signUp"] .deliverToBar .addressWrap,
body[data-route="signUp"] .deliverToInline { display:none; }

.deliverToBar {
  display: none;
  position: relative;
  text-align: center;
  font: var(--zrFont);
  font-size: .875rem;
  border-bottom: 1px solid var(--zrLightBorder);
  height: 3rem;
  line-height: 3rem;
}

.toAddress {
  cursor: pointer;
  font: var(--zrFontSemi);
}

.addAddress {
  color: var(--zrPrimaryDark);
}

.addAddress:hover {
  text-decoration: underline;
}

.deliverToInline {
  position: relative;
  height: 40px;
  composes: ellipses from '../common/mixins.css';
  max-width: 390px;
  font: var(--zrFont);
  line-height: 40px;
}

/* See DeliverTo.DELIVER_TO_BAR_THRESHOLD */
@media screen and (max-width:1024px) {
  .deliverToBar {
    display: block;
  }
  .deliverToInline {
    display: none;
  }
}
