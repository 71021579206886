.verificationForm {
  max-width: 360px;
  margin: 0 auto;
}

.verificationHeader {
  text-align: center;
  margin: 0 0 1.5rem;
  font: var(--zrFontSemi);
  font-size: 1.25rem;
}

.codeError {
  display: flex;
  margin-top: -.5rem;
  color: var(--zrTextRed);
}

.codeError svg {
  font-size: 18px;
}
.codeError span {
  flex: 1;
  font-size: .8rem;
  margin-left: .25rem;
}

.buttonWrap {
  margin: 1rem 0 0;
}

/* phone number on separate line */
@media screen and (max-width:480px) {
  .verificationHeader .phoneNumber {
    display: block;
    margin: .25rem 0;
  }  
}