
.modalAlert {
  width: 95%;
  max-width: 448px;
  position: relative;
  box-sizing: border-box;
  margin: 4rem auto;
  border-radius: 8px !important;
  outline: none !important;
}

@media screen and (max-width:600px) {
  .modalAlert {
    margin: 4rem 1rem;
    width: auto;
  }
}

.modalAlertContent {
  padding: 0px 28px 0px 0px;
}

/* CloseModalIcon */
.closeWrapper {
  position: absolute;
  top: 0;
  right: 0;
}

.closeIcon {
  cursor: pointer;
  position: absolute;
  z-index: 2;
  right: 10px;
  top: 10px;
  height: 1rem;
  background-color: #fff;
  padding: .25rem;
  border-radius: 50%;
  box-shadow: 0 0 3px #13460188;
}

.closeLabel {
  cursor: pointer;
  width: 100px;
  margin: 8px auto 0;
  text-align: center;
  font-weight: 600;
  line-height: 150%;
  text-decoration: underline;
}
