.productGridWithFilters {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  min-height: 80vh;
  width: 1100px;
  max-width: 100%;
  margin: 0 auto;
}

.headerWithFilters {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-basis: 100%;
  margin-top: 1.125rem;
}

.hdrFilterWrap {
  display: none;
  cursor: pointer;
  font: var(--zrFontSemi);
}

.filterIcon {
  margin-right: .5rem;
  height: 20px;
  vertical-align: text-top;
}

.filterCol {
  flex: 0; /* part of productGridWithFilters grid */
  max-width: 160px;
}

.productGrid {
  flex: 1;
  margin-bottom: 2rem;
}

/* Suppress the left-side filters */
@media screen and (max-width:1024px) {
  .productGridWithFilters {
    min-height: 70vh /* prevent collapse when filtering */
  }
  .filterCol {
    flex-basis: 100%;
    max-width: 100%;
  }
  /* show the filter icon */
  .hdrFilterWrap {
    display: block;
  }
}  

@media screen and (max-width:800px) {
  .filterCarouselWrap {
    max-width: 100vw;
  }
}

/** 
 * Cart col removed from layout at 1200 
 * @media screen and (max-width:1200px) {
 */  

@media screen and (max-width:600px) {
  .filterCarouselWrap {
    margin: 0 -1rem;
  }
}

/* product detail page within dispensary markup */
.productDetailPageWrap {
  width: 600px;
  margin: 1rem auto;
  max-width: calc(100vw - 2rem);
}

/******* NoMatchingProducts Component ****************/

.noResults {
  position: sticky;
  top: 50vh;
  margin-right: 160px;
  text-align: center;
  font-size: 1.125rem;
}

.sorryMsg {
  margin-bottom: 12px; 
}

.noResultsShowAll {
  cursor: pointer;
  font: var(--zrFontSemi);
  text-decoration: underline;
  color: var(--zrPrimaryDark);
  margin: .75rem 0;
}

/* Adjust centering when left-side filters are suppressed */
@media screen and (max-width:1024px) {
  .noResults {
    margin-right: 0;
  }
}

