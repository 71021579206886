.adjustQuantity {
  margin: 0 2rem 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.adjustQuantity.stacked {
  margin: 1rem 0 0;
  justify-content: center;
}

.control {
  cursor: pointer;
}

/* Limit 1 products etc. */
.control.disabledControl {
  cursor: default;
  opacity: .2;
}

.control,
.countWrap {
  flex: 0;
}

.control svg {
  vertical-align: middle;
}

.count  {
  min-width: 72px;
  box-sizing: border-box;
  margin: 0 1rem;
  padding: .5rem .25rem;
  border-radius: .25rem;
  background-color: #edf0f2;
  text-align: center;
  font: var(--zrFontBold);
}

@media screen and (max-width:800px) {
  .adjustQuantity {
    margin: 1rem 1rem 1rem 0;
  }

  .count  {
    min-width: 42px;
    box-sizing: border-box;
    margin: 0 .5rem;
  }
}

/* Related to button margin in the ProductDetailsCard */
@media screen and (max-width:767px) {
  .adjustQuantity {
    margin: .5rem 1rem .5rem 0;
  }
}
