
/* Burger Menu Items */
.menuItems {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 3rem;
  background-color: #fff;
  transform: translateX(-105%);
  height: 100vh;
  text-align: left;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
}

.open.menuItems {
  transform: translateX(0);
  box-shadow: 0 0 24px #ccc;
}

.menuItem {
  box-sizing: border-box;
  min-width: 340px;
  font: var(--zrFontBold);  
  font-size: 1.125rem;
  padding: 1rem 2rem;
  color: #000;
  text-decoration: none;
  transition: color 0.3s linear;
  border-bottom: 1px solid #e6e7e8;
  outline: none;
  cursor: pointer;
}

.menuItemSale {
  color: var(--zrPromoRed);
}

.menuItem:focus,
.menuItem:active {
  outline: none;
}

.menuItem:first-child {
  border-top: 1px solid #e6e7e8;        
}

.menuItem:hover {
  background-color: #f7f7f7;
}

.itemIcon {
  color: black;
  background-color: white;
  width: 20px;
  height: 20px;
  line-height: 22px;
  display: inline-block;
  vertical-align: -3px;
  text-align: center;
}

.itemIconSale{
  color: var(--zrPromoRed);
}
.newItem {
  color: var(--zrPromoRed);
  font-style: italic;
  font-size: 13px;
  margin-left: 2px;
}

@media screen and (max-width: 480px) {
  .menuItems {
    width: 100%;
  }
}
