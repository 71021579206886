.formWrap {
  padding: 0 2rem .5rem;
}

.inputLabel {
  margin: 0 0 1rem;
  font: var(--zrFontBold);
  font-size: 1.25rem;  
}

.signIn {
  cursor: pointer;
  text-decoration: underline;
  font: var(--zrFontSemi); 
}

@media screen and ( max-width:480px ) {
  .formWrap {
    margin-top: -1rem;
    box-sizing: border-box;
    min-width: 86vw;
    padding: 0 .5rem 1rem;
  }
  .inputLabel {
    font-size: 1.125rem;
  }
}
