.promo { 
  margin-top: 1.5rem;  
  padding: .5rem 1rem;
  font-size: .875rem;   
}

.promo p {
  line-height: 140%;
  margin: 0 0 12px;
}

.promo p:last-of-type {
  margin: 0;
}

.innerGrid {
  display: flex;
  column-gap: 1rem;
}

.gridCol {
  flex: 1;
}

/* single column below iPad width */
@media screen and (max-width:767px) {
  .innerGrid {
    display: block;
  }
  .gridCol:first-of-type {
    margin-bottom: 12px;
  }
}
