/* OrderLimitPillForModal */
.qtyLimit {
  color: #000;
  font-weight: 600;
  font-style: italic;
  line-height: 100%;
  white-space: nowrap;
  letter-spacing: .5px;
  text-align: center;
  margin: 1px 0 6px;
  flex-basis: 100%;
}

.sup {
  margin: 0 .25rem;
  font-size: 175%;
  vertical-align: -9px;
}

@media  screen and (min-width:600px) {
  .qtyLimit {
    margin: -3px 0 6px;
  }
}
