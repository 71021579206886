/**
 * Material UI TextField overrides
 * See API: https://material-ui.com/api/text-field/
 */

/* Input- sizing, padding and margin defaults */



/* New User */

:global .MuiFormControl-root.MuiTextField-root { 
  width: 100%;
  margin: 0 0 1rem;
}

:global .MuiFilledInput-root .MuiFilledInput-input { 
  font-size: 1rem; 
  border-radius: 3px;
  padding: 25px 1rem 10px; 
  background-color: #f6f6f6;
}

/* Tweak alignment of the animated form label */
:global .MuiTextField-root .MuiInputLabel-filled {
  transform: translate(16px, 24px) scale(1);
  font-size: 1rem;
}

:global .MuiTextField-root .MuiInputLabel-filled.MuiInputLabel-shrink {
  transform: translate(16px, 10px) scale(0.75);
}

:global .MuiFormControl-root .MuiFormHelperText-root.Mui-error .MuiSvgIcon-root {
  vertical-align: middle;
}
  
:global .MuiFormControl-root .MuiFormHelperText-root.Mui-error span {
  font-size: .8rem;
  vertical-align: middle;
  line-height: 24px;
  margin: 0 .5rem;
}

/* Remove autofill blue background in Chrome */
input:-webkit-autofill { 
  -webkit-background-clip: text;
}

/* Label focused state */
:global .MuiFormLabel-root.Mui-focused {
  color: var(--zrPrimaryVeryDark) !important;
}

/* Input fociused state */
:global .MuiFilledInput-underline.Mui-focused:after {
  border-bottom: 2px solid var(--zrPrimaryDark) !important;
}

@media screen and (max-width: 480px) {
  :global .MuiFilledInput-root .MuiFilledInput-input,
  :global .MuiTextField-root .MuiInputLabel-filled { 
    font-size: 1.125rem;
  }
}
