.referralOuter {
  box-sizing: border-box;  
  max-width: 800px;
  margin:0 auto;
  padding: 4rem 1rem 5rem;
}

.referralWrap {
  box-sizing: border-box;  
  max-width: 560px;
  margin:0 auto;
  padding-top: 1rem;
}

.header {
  color: var(--zrPrimaryDark);
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.semiBold {
  font: var(--zrFontSemi);
  font-size: inherit;
}

.circleNum {
  color: var(--zrPrimaryVeryDark);
  background-color: var(--zrOrange);
  border-radius: 50%;
  display: inline-block;
  height: 26px;
  width: 26px;
  line-height: 1.5rem;
  text-align: center;
}

.creditRules {
  font-style: italic;
  font-size: .875rem;
}

.btnWrap {
  margin: .5rem 0;
  text-align: center;
}

.instructions {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 500;
  margin: 0 0 1.5rem;    
}

.lastInstruct {
  margin-bottom: 0;
}

.shareHeader {
  font: var(--zrFontSemi);
  font-size: 1.125rem;
  margin: 2rem 0 .75rem;
}

.minorAlert {
  font-style: italic;
  font-size: 1.125rem;
}

.signInLink {
  cursor: pointer;
  font: var(--zrFontSemi);
  font-size: 1.25rem;
  text-decoration: underline;
  font-style: italic;
  color: var(--zrPrimaryDark);
  margin: 0 .25rem;
}

@media screen and (max-width:480px) {
  .referralOuter {
    padding: 2rem .5rem 3rem;
  }
  .creditRules {
    font-size: .75rem;
  }
  .btnWrap button {
    margin-top: .5rem;
    width: 100%;
  }
}
