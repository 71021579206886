.controls {
  position: static; /* maintain common classes */
}

.cartItem {
  position: relative;
  margin: 0 0 .5rem;
  border-bottom: 1px solid #e6e7e8;
}

.itemDetails {
  display: flex;
  font-size: .8125rem;
  margin: 0 0 .5rem;
}

.itemTitle {
  flex:10;
  padding-right: 36px; /* reserve for long SpecialPricingLabel */
}

.itemCount,
.itemPrice {
  flex:1;
}

.itemPrice {
  position: relative;
  text-align:right;
}

.specialPricingLbl {
  position: absolute;
  top: 20px;
  right: 0;
  display: block;
  text-align: center;
  color: #fff;
  padding: 0 8px 1px;
  background-color: #C83C32;
  border-radius: 18px;
  font-size: 12px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 1px;
  max-width: 120px;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.specialQtyLimit {
  background-color: #000;
}

.superDeal {
  font-size: 13px;;
  letter-spacing: normal;
  background-color: #907725;
}

.qtyLimitCircle {
  display: inline-block;
  position: relative;
  left: 5px;
  top: -1px;
  background-color: #000;
  border-radius: 50%;
  width: 14px;
  padding: 0px 0 2px;
  font-size: 12px;
  line-height: 100%;
}

.itemCount {
  min-width: 28px;
}

.itemRemoveWrap {
  position: relative;
  z-index: 2; /* stack over product image area */
}

.itemRemove {
  cursor: pointer;
  color: var(--zrPrimaryDark);
  position: absolute;
  top: -2px;
  font: var(--zrFontBold);
  font-size: .8125rem;
}

.itemImg {
  display: block;
  max-width: 100%;
  max-height: 80px;
  border-radius: .5rem;
  margin: 0 auto .5rem;
}

.itemDetail {
  display: flex;
  align-items: flex-end;
  padding-bottom: 3px;
  border-bottom: 1px solid #608609;
}

.imageWrap {
  position: relative;
}

.plainTextControl {
  position: absolute;
  cursor: pointer;
  color: #fff;
  background: var(--zrPrimaryDark);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  text-align: center;
  line-height: 26px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 23px;
  font-weight: 700; /* courier */
  margin: 0 3px 0 0;
  transform: translateY(-50%);
}

.disabledControl {
  cursor: default;
  opacity: .2;
}

.increase { top: 50%; right: 2rem;}
.decrease { top: 50%; left: 2rem;}

.qtyLimit {
  color: #1879c3;
  position: absolute;
  right: 0px;
  top: -16px;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: .5px;
}

.notAvailableWrap {
  position: relative;
  text-align: center;
  margin: 1rem;
  padding: .5rem;
  background-color: #fff;
  border-radius: .25rem;
}

.notAvailableMsg {
  margin: 0 0 .5rem;
}

.notAvailableMask {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #94b6d691;
  border-radius: .25rem;
}
