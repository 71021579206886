.productCardWrap {
  background-color: #fff;
  cursor: pointer;
}

/* flex layout mostly for mobile */ 
.productCard {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  box-sizing: border-box;
  min-height: 150px;
}

.productImageWrap {
  position: relative;
  flex: 0 0 150px;
  min-height: 150px;
}

.productBody {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: .25rem 2rem .75rem .75rem;
}

.brand {
  order: 2;
  color: #000;
  font-size: .875rem;
  composes: ellipses from '../common/mixins.css'; 
}

.productName {
  order: 1;
  color: var(--zrBlack);
  font: var(--zrFontBold);
  font-size: 1rem;
  margin:.5rem 0;
  text-decoration: none;
  composes: twoLines from '../common/mixins.css'; 
} 

.productInfo {
  margin: .5rem 0 0;
}

.productPriceWrap {
  position: relative;
  padding: 0 .75rem;
}

/* pricing/qty */
.mobileControls {
  display: none;
}
.desktopControls {
  padding:0 .75rem;
}

.productPrice {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0 1.25rem 0 1rem;
  margin: 0 .75rem;
  font: var(--zrFontBold);
  font-size: .875rem;
  line-height: 3rem;
  text-align: center;
  background-color: #eaeff2;
  border-radius: .375rem;
  color: #000;
}

.discountIcon {
  margin: -1px 5px 0;
}

.orderQuantity {
  position: absolute;
  top: -.75rem;
  right: 0;
  font: var(--zrFontSemi);
  border-radius: 1rem;
  background-color: #fff;
  width: 1.675rem;
  line-height: 1.675rem;
  text-align: center;
  box-shadow: 0 0 3px #000;
}

.addedWrapper {
  font-weight: normal;
  position: absolute;
  z-index: 0; /* so as not to overlap sticky category filter */
  top: -0.75rem;
  right: 0;
}

@media screen and (max-width:600px) {
  .productBody {
    padding: 0 0 0 1rem;      
  }
  .productImageWrap {
    position: relative;
    flex: 0 0 120px;
    min-height: 120px;
  }
  /* pricing/qty */
  .mobileControls {
    order: 3;
    display: block;
    margin-top: 1.25rem;
  }
  .desktopControls {
    display: none;
  }
}
