.wrapper {
  display:flex;
  justify-content: space-between;
  align-items: baseline;
}

.salePriceWrapper {
  justify-content: flex-start;
}

.amount {
  color: inherit;
}

.onSale {
  text-decoration: line-through;
}

.discountedPrice {
  color: var(--zrTextRed);
  font-style: italic;
  font-size: 115%;
  margin: 0 0 0 .375rem;
}

/* Prevent ugly wrapping in mobile */
@media screen and (max-width:600px) {
  .discountedPrice.longTeaser {
    font-size: 100%;
  }
}
