.heroSection {
  padding: 1.5rem 0 0 0;
}

.heroWrap {
  position: relative;
  overflow: hidden;
  border-radius: .5rem;
  height: 274px;
  width: 100%;
}

.brandHero {
  display: block;
  position: absolute;
  max-width: 1300px; /* sample image is 500x1500 */
  max-height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: .5rem;
}

.brandHeroMobile {
  display: none;
}

.brandLogo {
  display: block;
  padding: .5rem;
  box-sizing: border-box;
  background-color:#fff;
  max-height: 200px;
  max-width: 200px;
  border-radius: .5rem;
  position: absolute;
  left: 1.5rem;
  bottom: 1.5rem;
}

/* For Ethos/Desktop only ATM */
.brandLogo.logoTop {
  bottom: auto;
  top: 1.5rem;
}

.aboutLink {
  cursor: pointer;
  position: absolute;
  right: 1.5rem;
  bottom: 1.5rem;
  border-radius: 4px;
  padding: 4px 9px 4px 12px;
  font: var(--zrFontSemi);
  font-size: .875rem;
  background-color: #fffc;
}

@media screen and (max-width:800px) {
  .brandLogo {
    max-height: 120px;
    max-width: 120px;
    left: 0.5rem;
    bottom: 0.5rem;
  }
  .brandLogo.logoTop {
    bottom: auto;
    top: .5rem;
  }
  .aboutLink {
    right: 0.5rem;
    bottom: 0.5rem;
  }
}

@media screen and (max-width:480px) {
  .heroSection {
    padding: 0;
    margin: 0 -1rem;
  }
  .heroWrap {
    height: 160px;
    border-radius: 0;
  }
  .aboutLink {
    font-size: .75rem;
    padding: 4px 6px 4px 9px;
  }
  /* Swap hero images! */
  .brandHero {
    display: none;
  }
  .brandHeroMobile {
    display: block;
  }
  /* For Ethos only ATM */
  .brandLogo.logoTop {
    bottom: .5rem;
    top: auto;
  }
}
