.framedContentWrap {
  position: relative;
  padding: .5rem 0 1rem;
}

/* for development w/o iframe */
:global body.testMode {
  background-color: #84c0fa;
}
:global(.testMode) .framedContentWrap {
  margin: 40px;
}
:global(.testMode) #header {
  display:none;    
}

.framedContentWrap :global .MuiFormControl-root.MuiTextField-root {
  margin-bottom: .5rem;
}

.hdrLarge {
  font: var(--zrFontBold);
  font-size: 2.25rem;
  line-height: 118%;
  margin: 0 0 1.75rem;
  color: #fff;
}

.btnWrap {
  margin: 1rem auto 0;
  max-width: 90vw;
}

.btnWrap button {
  margin-top: 0;    
}

.minorDetail {
  margin-top: .5rem;  
  color: #000;
  font-size: .875rem;
  font-weight: 600;    
}

.appLink {
  cursor: pointer;
  font-weight: 800;    
}

.appLink:hover {
  text-decoration: underline;    
}

.etaWrapper {
  margin: 0 0 .675rem;
}

@media screen and (max-width:600px) {
  :global(.testMode) .framedContentWrap {
    margin: 0;
  } 
  .hdrLarge {
    font-size: 2.125rem;
    margin: 0 0 2.5rem;
  }
  .etaWrapper {
    margin: 0 0 .5rem;
  }
}

@media screen and (max-width:480px) {
  .btnWrap {
    width: 100%;
  }
  
  .btnWrap button {
    width:100%;    
  }
}
