.alert {
  margin: 1.25rem 0 0;
}

@media screen and (min-width:600px) {
  .alert :global .MuiAlert-message {
    width: 420px;
  }
}

.clarification {
  margin-bottom: 8px;
}

.clarification:last-of-type {
  margin-bottom: 0;
}
