
.emoji {
  font-style: normal;
  margin: 0 .5em;
}

/* SuperDealDetails */
.detailsGrid {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 2px;
}

.dealLabel {
  color: #000;
  font-style: italic;
  font-weight: 600;
}

.dealLabelWithLimit {
  color: #78631f;
  font-style: italic;
  font-weight: 600;
}

/* Super Deal Tooltip */
.tipWrapper {
  white-space: nowrap;
  margin-top: 4px;
}

/* SaleLabelModal/SuperDealDetailsForModal */
.superDeal {
  color: #78631f;
}

.superDeal .qtyLimitPill {
  display: inline-block;
  vertical-align: 2px;
  font-size: 13px;
  line-height: 100%;
  padding: 1px 8px 3px;
  margin: 0 0 0 10px;
  border-radius: 4px;
  color: #fff;
  background-color: #000;
}
