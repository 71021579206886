/* Berkshire Roots is 155px + 36px right margin for filter column */

/**
 * Set in Dispensary.module.css for wrapper:
 *
 * .filterCol
 *   flex: 0;
 *   max-width: 160px;
 */

.productInfoFilter {
  box-sizing: border-box;
  padding: 0 .5rem .75rem 0;
  font-size: .875rem;
  min-width: 160px;
  background-color: #fff;
}

.productInfoFilter:first-of-type {
  padding-top: .75rem;
}

.productInfoFilter::after {
  display: block;
  height: 0;
  content: '';
  border-bottom: 1px solid #e6e7e8; 
}
.sectionHdr {
  position: relative;
  cursor: pointer;  
  font: var(--zrFontBold);
  margin-bottom: .75rem;    
}

.sectionHdr[data-show-content="true"] {
  margin-bottom: 1rem;  
}

.sectionHdr[data-show-content="true"] svg {
  transform: rotate(90deg);
}

.filterLink {
  cursor: pointer;
  position: relative;
  margin: 1rem 0;
}

.filterLink:first-of-type {
  margin-top: .5rem;    
}
.filterLink.selected {
  cursor: default;
  font: var(--zrFontBold);   
} 
.filterLink .selectedIcon {
  display: none;
}

.filterLink.salePricing {
  font: var(--zrFontSemiItalic);
  color: var(--zrTextRed);
}

.filterLink.salePricing.selected {
  font: var(--zrFontBoldItalic);
}

/* MODAL */
.productInfoFilter.inModal {
  padding: 0;
  border-bottom: 2px solid #fff;
}

.productInfoFilter.inModal::after {
  display: none; 
}

.productInfoFilter.inModal .sectionHdr {
  background-color: #272a3e22;
  padding: .75rem 1rem;
  margin: 0 2px;
  border-radius: .25rem; 
}
.productInfoFilter.inModal .sectionHdr svg {
  position: absolute;
  right: 1rem;
  top: 9px;
}  

.productInfoFilter.inModal .filterLink {
  padding: .75rem 1rem;
  margin: 0;
}

.productInfoFilter.inModal .productInfoFilter::after {
  border-bottom: none;
}

.productInfoFilter.inModal .filterLink:hover {
  background-color: #eeeeee99;
}

.productInfoFilter.inModal .filterLink.selected .selectedIcon {
  position: absolute;
  right: 18px;
  top: .5rem;
  display: block;
}

/* Hide non-modal horizontal pill layout of sidebar filters */
@media screen and (max-width:1024px) {
  .productInfoFilter {
    display: none;
  }
  .productInfoFilter.inModal {
    display: block;
  }
}