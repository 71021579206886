/* AddressSearch */

.inputWrap {
  width: 480px;
  max-width: 100%;
  white-space: nowrap;
  position: relative;
}

.inputWrap :global .MuiFormControl-root.MuiTextField-root {
  display: block;
}

.inputWrap :global .MuiTextField-root .MuiInputLabel-filled {
  padding-left: 2.25rem;
  padding-right: 4rem;    
}

.inputWrap :global .MuiInputBase-root {
  display: block;
  white-space: nowrap;
}

/* The address text input */
.inputWrap :global .MuiFilledInput-input {
  background-color: #fff;
  box-sizing: border-box;
  height: 48px;
  padding: 14px 14px 14px 2.25rem;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* input underline */ 
.inputWrap :global .MuiFilledInput-underline:after {
  border-color: var(--zrPrimaryDark);
}

/* padding specific to "Get Started" button within input */
.inputWrap.withSubmit :global .MuiFilledInput-input {
  padding-right: 9rem; /* 8rem = Get Started, 9rem = Shop Delivery */
}  

/* arrowOnly = FRAMED SEARCH MOSTLY */
/* padding for small icon/button within input */
.inputWrap.withSubmit.arrowOnly :global .MuiFilledInput-input {
  height: 54px;
  padding-right: 3.5rem;
  border: 1px solid var(--zrPrimaryDark);
}
/* input underline hide on marketing site */ 
.inputWrap.withSubmit.arrowOnly :global .MuiFilledInput-underline:after {
  display: none;
}
/* padding for small icon/button within input */
.inputWrap.withSubmit.arrowOnly .pinIcon {
  left: 11px;
  top: 17px;
}
.inputWrap.withSubmit.arrowOnly .continueButton {
  right: 8px;
  top: 7px;
}
.inputWrap.withSubmit.arrowOnly :global .MuiTextField-root .MuiInputLabel-filled {
  transform: translate(0px, 19px) scale(1);
}
/* End /framedSearch tweaks */

.inputWrap :global  .MuiTextField-root .MuiInputLabel-filled {
  transform: translate(0px, 16px) scale(1);
}

.inputWrap :global .MuiTextField-root .MuiInputLabel-filled.MuiInputLabel-shrink {
  display: none;  
  transform: translate(8px, 8px) scale(0.75);
}

.inputWrap .pinIcon {
  position: absolute;
  left: .5rem;
  top: 13px;
  z-index: 1;
}

.inputWrap .pinIcon svg {
  font-size: 20px;
}

.inputWrap .continueButton {
  cursor: pointer;
  position: absolute;
  right: 4px;
  top: 4px;
  height: 40px;
  padding-left: 6px;
  padding-right: 6px;
  background-color: var(--zrPrimaryDark);
  color: #fff;
  font: var(--zrFontBold);
  font-size: 1.125rem;
  border-radius: 50%;
  border: none;
}

.inputWrap .continueButton.enabledButton:hover {
  background-color: var(--zrBlack);
}

.inputWrap .continueButton svg {
  font-size: 28px;
}

/* address is undefined/not selected yet */
.inputWrap .continueButton.disabledButton {
  cursor: default;
  background-color: #9cbbde;
}

@media screen and (max-width:480px) {
  .inputWrap {
    width: auto;
    max-width: none;
  }
}

/* AddressForm component */

/* City, State, Zip */
.addressInputTriple { 
  display: flex;
  flex-wrap: wrap;
}

.addressInputTriple :global .MuiFormControl-root.MuiTextField-root {
  flex: 1;
  margin-right: 1rem;
} 

.addressInputTriple :global .MuiFormControl-root.MuiTextField-root:first-of-type {
  flex: 1.75;    
}

.addressInputTriple :global .MuiFormControl-root.MuiTextField-root:last-of-type {
  margin-right: 0;
}

.formButtonWrap {
  text-align: right;    
}

.formButtonWrap button {
  min-width: 104px; /* match grid */  
  margin-left: 1rem;    
}

@media screen and (max-width:480px) { 
  .addressInputTriple :global .MuiFormControl-root.MuiTextField-root:first-of-type { 
    flex-basis: 100%;
    margin-right: 0;    
  }    
}
