/* Mostly to keep it pretty in MS Edge */ 
.horizontalScroll {
  overflow-x: auto;
  overflow-y: visible;
  -ms-overflow-style: none;    /* Older Internet Explorer, Edge */
  scrollbar-width: none;       /* Firefox - nice with horizontal scroll */
  overscroll-behavior-x: none; /* Prevent fwd/back nav */  
}

.horizontalScroll::-webkit-scrollbar {
  display: none;
}  

.verticalScroll {
  overflow-x: visible;
  overflow-y: auto;
  -ms-overflow-style: none; /* Older Internet Explorer, Edge */
  scrollbar-width: none;    /* Firefox */  
}

/* 1 line with ellipsis */
.ellipses {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;   
}

/* 2 lines with ellipsis */
.twoLines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.maskRight {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
  bottom: 0;
  content: '';
  width: 1.5rem;
  background-color: #fff;
  -webkit-mask-image: linear-gradient(90deg,hsla(0,0%,100%,0),#fff 16px,#fff);
}

.maskLeft {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  bottom: 0;
  content: '';
  width: 1.5rem;
  background-color: #fff;
  -webkit-mask-image: linear-gradient(-90deg,hsla(0,0%,100%,0),#fff 16px,#fff);
}

.XverticalScroll::-webkit-scrollbar {
  width: 12px;
  padding: 2px;
  background-color: transparent;
}

/* Apply MacOS Chrome scrollbar styling to Windows Edge */
body[data-is-edge="true"] .XverticalScroll::-webkit-scrollbar-thumb {
  width: 14px;
  background-color: #7f7f7f; /* Chrome */
  border-radius: 5px;
}
