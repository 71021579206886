/* Google Location Search in modal positioning hack/fix */
body[data-modal-open="true"] :global .pac-container {
  position: fixed !important;
}

.modalContent {
  position: fixed;
  top: 4rem;
  left: 50vw;
  transform: translateX(-50%);
  box-sizing: border-box;
  width: 600px;
  max-width: 95vw; 
  min-height: 8rem;
  max-height: var(--zrModalMaxHeightDT);
  padding: 2rem 1rem 1rem;
  color: #000;
  background-color:#fff;
  border-radius: .75rem;
  overflow: hidden; /* rounded corners */
}

.modalInner {
  font-weight: 400;
  padding: 0;
  text-align: left;
}

.scrollingInner {
  max-height: 66vh;
  overflow-y: auto;
}

.modalContent .closeIcon {
  cursor: pointer;
  position: absolute;
  z-index: 2;  /* was 5 */
  right: .5rem;
  top: .5rem;
  height: 1rem;
  background-color: #fff;
  padding: .625rem;
  border-radius: 50%;
  box-shadow: 0 0 3px #13460188;
}

.modalBGElem {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,.35);
}

@media screen and (max-width:480px) {
  .modalContent {
    top: 3.25rem;
    max-height: var(--zrModalMaxHeightMB);
    padding: 2.75rem .5rem .5rem; /* affects product modal scroll css */
  }
  .modalInner {
    padding: 0 .5rem;
  }  
}
