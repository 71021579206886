.signInForm {
  width: 425px;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0 auto 4rem;
}

.loginForgotPassword {
  margin-top: -10px;
}

.signUpLink {
  color:#333;
  font-size: 18px;  
  font-weight: normal;
  text-decoration: none;
  cursor: pointer;
}

.passwordWrap {
  position: relative;
}

.showPassword {
  font-size: 1rem;
  line-height: 100%;
  position: absolute;
  top: 6px;
  right: 6px;
  min-width: auto;
  padding: 9px 12px 6px;
}

@media screen and (max-width:480px) {
  .showPassword {
    top: 7px;
  }
}

.btnWrap {
  text-align: center;
}

@media screen and (max-width:600px) {
  .signUpLink {
    text-align: center;
    margin: 1rem 0;
  }    
}

/* MUI overrides */
.loginForm :global .MuiFilledInput-root {
  background-color: var(--zrLightInputBackground);
}

.loginForm :global .MuiFilledInput-root.Mui-focused,
.loginForm :global .MuiFilledInput-root:active,
.loginForm :global .MuiFilledInput-root:hover {
  background-color: #fff;
}