.selectDay {
  display: flex;
  white-space: nowrap;
  max-width: calc(100vw - 2rem);
  /* horizontal scroll mixin */
  composes: horizontalScroll from '../common/mixins.css'; 
}

.dayItem {
  cursor: pointer;
  color: #555;
  font: var(--zrFontSemi);
  text-align: center;  
  padding: .75rem .75rem;
  margin: 0 .75rem .75rem 0;
  border-radius: .25rem;
  min-width: 80px;
  box-sizing: border-box;
  background-color: #ededed;
}

.dayItem span { 
  display:block;
  white-space: nowrap;
  margin-top: .25rem;
  font-size: .75rem;
}
  