/** 
 * Hide header, footer nav and chat widget
 */  
:global body[data-minimal-nav="true"] #header,
:global body[data-minimal-nav="true"] #deliver_to_upper,
:global body[data-minimal-nav="true"] #deliver_to_lower,
:global body[data-minimal-nav="true"] #delto_bar,
:global body[data-minimal-nav="true"] #footerNav,
:global body[data-minimal-nav="true"] #footer,
:global body[data-minimal-nav="true"] .intercom-lightweight-app {
  display: none;
}

:global body[data-minimal-nav="true"] #footer {
  bottom: 0;  
}
