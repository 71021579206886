.plainForm {
  width: 456px;
  max-width: 82vw; /* for modal display in mobile (Log In) */
  box-sizing: border-box;
  margin: 0 auto;
  padding-bottom: 2rem;
}

.plainForm :global(.MuiFormControl-root .MuiFormHelperText-root.Mui-error) {
  color: var(--zrPrimaryDark);
}


.cancel {
  cursor: pointer;
  margin-top: 2rem;
  font: var(--zrFontSemi);
  font-size: 1.2rem;
  color: var(--zrPrimaryDark);
}

.plainInputs :global(.MuiFilledInput-root .MuiFilledInput-input) { background-color: rgb(236, 236, 236); }
.plainInputs :global(.MuiFilledInput-root .MuiFilledInput-input):active,
.plainInputs :global(.MuiFilledInput-root .MuiFilledInput-input):hover { background-color: rgb(246, 246, 246); }
