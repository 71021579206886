.spinnerWrap {
  position: relative; 
  text-align: center;
  margin: 7rem auto; 
}

.spinnerWrapTight {
  position: relative; 
  text-align: center;
  margin: 1rem auto; 
}

/* SpinnerWrapper styles (.spinWrap) */
.spinWrap {
  display: inline-block;
  position: relative;
}

/* fix safari button alignment issue in flex grid */
.spinWrap button {
  margin-left: 0;
  margin-right: 0;
}

.spinWrapMask {
  position: relative;
}

.taskMessage {
  text-transform: none;  
  font-size: .75rem;
  font-weight: normal;
}

.taskHdr {
  font: var(--zrFontSemi);
  font-size: .875rem;
  margin: .5rem 0 0 1.75rem;
}

/* end SpinnerWrapper */

.ldsRing {
  display: inline-block;
  position: relative;
}

.ldsRing div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.ldsRing div:nth-child(1) {
  animation-delay: -0.45s;
}

.ldsRing div:nth-child(2) {
  animation-delay: -0.3s;
}

.ldsRing div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
