.wrap404 {
  color: var(--zrPrimaryDark);
  font-size: 2.5rem;
  line-height: 3rem;
  padding: 3rem 0;
  text-align: center;
}

.notFoundContent {
  margin: 2rem 0;
  font-size: 1.25rem;
  line-height: 133%;
}

.notFoundMsg {
  color: #000;
}

.notFoundContent a:active,
.notFoundContent a:link,
.notFoundContent a:visited {
  color: var(--zrPrimaryDark);
}
