.resetWrap {
  margin: 0 auto;
  padding-top: 2rem;
  width: 500px;
  max-width: calc(100vw - 2rem);
}

.invalidCode {
  text-align: center;
  font-style: italic;
  font-size: 1.2rem;
  line-height: 133%;
  margin: 1rem 0;    
}

.successMsgWrap {
  text-align: center;
}    

.successMsg {
  font: var(--zrFontSemi);
  font-size: 1.2rem;
  margin: 0 0 1.2rem;
}

/* reset email form */

.plainForm {
  width: 456px;
  max-width: 82vw; /* for modal display in mobile (Log In) */
  box-sizing: border-box;
  margin: 0 auto;
}

.plainForm :global(.MuiFormControl-root .MuiFormHelperText-root.Mui-error) {
  color: var(--zrPrimaryDark);  
}

.headerWrap {
  margin: 1rem 0 0;
}

.cancel {
  cursor: pointer;
  color: var(--zrPrimaryDark);
  font: var(--zrFontSemi);
  font-size: 1.125rem;
  margin-bottom: 1rem;
}

.plainForm :global(.MuiFormControl-root .MuiFormHelperText-root.Mui-error) svg {
  vertical-align: middle;
}

.plainInputs :global(.MuiFilledInput-root .MuiFilledInput-input) { background-color: rgb(236, 236, 236); }
.plainInputs :global(.MuiFilledInput-root .MuiFilledInput-input):active,
.plainInputs :global(.MuiFilledInput-root .MuiFilledInput-input):hover { background-color: rgb(246, 246, 246); }
