.modalBg, .modalMsg {
  position: fixed;
  z-index: 1000;
}  
.modalBg {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #272A3EDD;    
}
.modalMsg {
  width: 375px;
  max-width: 90vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  border-radius: .5rem;
}
.modalInner {
  position: absolute;
  top: 60%;
  bottom: 0;
  right: 0;
  left: 0;
}
.graphic {
  width: 100%;
  border-radius: .5rem;
}
.is21, .not21 {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  font: var(--zrFontBold);
  padding: .6125rem 1.75rem;
  border-radius: .25rem;
  white-space: nowrap;
}
.is21 {
  top: 12%;
  color: #fff;
  background-color: var(--zrPrimaryDark);
}
.not21 {
  top: 42%;
  background-color: #fff;
  color: var(--zrPrimaryDark);    
}

/* tall image for tablet/mobile */
@media screen and (max-width:375px) {
  .not21 {
    top: 50%;
  }
}
